// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  font-family: "Raleway";
  font-weight: 200;
  font-size: 14px;
  position: fixed;
  text-align: center;
  width: 9%;
  height: 100%;
  left: 0;
  background-color: #006D83;
  color: #fff;
}

.logo {
  /* padding: auto; */
  position: absolute;
  max-width: 60%;
  max-height: 60%;
  font-size: 5px;
}

.nav-links {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-menu {
  list-style-type: none;
  padding: 20px;
  margin: 20px;
  width: 100%;
}

.sidebar-item {
  margin: 10px 0;
}

.nav-link {
  padding: auto;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px;
  transition: background-color 0.3s;
}

.nav-link svg {
  height: 32px;
  width: 32px;
  margin-bottom: 10px;
}

.nav-link:hover {
  background-color: #000000;
}

.active {
  font-weight: 900;
}

.forum-topics {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,OAAO;EACP,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".sidebar {\n  font-family: \"Raleway\";\n  font-weight: 200;\n  font-size: 14px;\n  position: fixed;\n  text-align: center;\n  width: 9%;\n  height: 100%;\n  left: 0;\n  background-color: #006D83;\n  color: #fff;\n}\n\n.logo {\n  /* padding: auto; */\n  position: absolute;\n  max-width: 60%;\n  max-height: 60%;\n  font-size: 5px;\n}\n\n.nav-links {\n  margin-top: 100px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.sidebar-menu {\n  list-style-type: none;\n  padding: 20px;\n  margin: 20px;\n  width: 100%;\n}\n\n.sidebar-item {\n  margin: 10px 0;\n}\n\n.nav-link {\n  padding: auto;\n  text-decoration: none;\n  color: #fff;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  line-height: 30px;\n  transition: background-color 0.3s;\n}\n\n.nav-link svg {\n  height: 32px;\n  width: 32px;\n  margin-bottom: 10px;\n}\n\n.nav-link:hover {\n  background-color: #000000;\n}\n\n.active {\n  font-weight: 900;\n}\n\n.forum-topics {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
