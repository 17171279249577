// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.love-author {
    bottom: 8px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: white;
}

.love-author a {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".love-author {\n    bottom: 8px;\n    width: 100%;\n    text-align: center;\n    font-size: 12px;\n    color: white;\n}\n\n.love-author a {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
