import {
    WebGLRenderer,
    SRGBColorSpace,
    PCFSoftShadowMap,
    Scene,
    PerspectiveCamera,
    HemisphereLight,
    DirectionalLight,
    PlaneGeometry,
    Mesh,
    MeshStandardMaterial,
    ShadowMaterial,
    Fog,
    Group,
    Color,
    CanvasTexture,
    AxesHelper,
    GridHelper,
    Vector3,
    Bone,
    
  } from 'three';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
  import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
  import TWEEN from '@tweenjs/tween.js';
  import MinGeometryFinder from './MinGeometryFinder';
  import MinSTLExporter from './MinSTLExporter';
  import * as THREE from 'three';


const beardPositionAdjustments = {
    "ava_head_narrow_oblong": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_oblong_narrow_facialhair_beardandmustache":      { x: 0, y: -1.197, z: -0.0090 },
        "ava_head_oblong_narrow_facialhair_shortBeardandmustache": { x: 0, y: -1.190, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_ThinBeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_ThinBeardandmustache":  { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_Thinmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_both_facialhair_archedmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_both_facialhair_downwardsmustache":       { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_narrow_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_wide_oblong": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_oblong_wide_facialhair_beardandmustache":      { x: 0, y: -1.198, z: -0.006 },
        "ava_head_oblong_wide_facialhair_shortBeardandmustache": { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_wide_facialhair_ThinBeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_wide_facialhair_ThinBeardandmustache":  { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_wide_facialhair_Thinmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_both_facialhair_archedmustache":        { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_both_facialhair_downwardsmustache":     { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_wide_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_wide_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_oblong_wide_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_oblong_wide_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_narrow_teardrop": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_teardrop_narrow_facialhair_beardandmustache":      { x: 0, y: -1.196, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_shortBeardandmustache": { x: 0, y: -1.190, z: 0 },
        "ava_head_teardrop_narrow_facialhair_ThinBeard":             { x: 0, y: -1.199, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_ThinBeardandmustache":  { x: 0, y: -1.199, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_Thinmustache":          { x: 0, y: -1.199, z: -0.004 },
        "ava_head_teardrop_both_facialhair_archedmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_both_facialhair_downwardsmustache":       { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_narrow_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_wide_teardrop": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_teardrop_wide_facialhair_beardandmustache":      { x: 0, y: -1.190, z: -0.0065 },
        "ava_head_teardrop_wide_facialhair_shortBeardandmustache": { x: 0, y: -1.193, z: 0 },
        "ava_head_teardrop_wide_facialhair_ThinBeard":             { x: 0, y: -1.195, z: 0 },
        "ava_head_teardrop_wide_facialhair_ThinBeardandmustache":  { x: 0, y: -1.195, z: 0 },
        "ava_head_teardrop_wide_facialhair_Thinmustache":          { x: 0, y: -1.195, z: -0.0065 },
        "ava_head_teardrop_both_facialhair_archedmustache":        { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_both_facialhair_downwardsmustache":     { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_wide_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_wide_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_teardrop_wide_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_teardrop_wide_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_narrow_kite": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_kite_narrow_facialhair_beardandmustache":      { x: 0, y: -1.199, z: -0.0094 },
        "ava_head_kite_narrow_facialhair_shortBeardandmustache": { x: 0, y: -1.197, z: -0.006 },
        "ava_head_kite_narrow_facialhair_ThinBeard":             { x: 0, y: -1.197, z: -0.004 },
        "ava_head_kite_narrow_facialhair_ThinBeardandmustache":  { x: 0, y: -1.197, z: -0.004 },
        "ava_head_kite_narrow_facialhair_Thinmustache":          { x: 0, y: -1.197, z: -0.004 },
        "ava_head_kite_both_facialhair_archedmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_both_facialhair_downwardsmustache":       { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_narrow_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_narrow_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_kite_narrow_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_narrow_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_wide_kite": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_kite_wide_facialhair_beardandmustache":      { x: 0, y: -1.195, z: -0.0065 },
        "ava_head_kite_wide_facialhair_shortBeardandmustache": { x: 0, y: -1.197, z: -0.0048 },
        "ava_head_kite_wide_facialhair_ThinBeard":             { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_kite_wide_facialhair_ThinBeardandmustache":  { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_kite_wide_facialhair_Thinmustache":          { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_kite_both_facialhair_archedmustache":        { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_both_facialhair_downwardsmustache":     { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_wide_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_wide_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_kite_wide_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_kite_wide_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_narrow_round": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_round_narrow_facialhair_beardandmustache":      { x: 0, y: -1.194, z: -0.0064 },
        "ava_head_round_narrow_facialhair_shortBeardandmustache": { x: 0, y: -1.187, z: -0.008 },
        "ava_head_round_narrow_facialhair_ThinBeard":             { x: 0, y: -1.197, z: -0.004 },
        "ava_head_round_narrow_facialhair_ThinBeardandmustache":  { x: 0, y: -1.197, z: -0.004 },
        "ava_head_round_narrow_facialhair_Thinmustache":          { x: 0, y: -1.197, z: -0.004 },
        "ava_head_round_both_facialhair_archedmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_both_facialhair_downwardsmustache":       { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_narrow_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_narrow_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_round_narrow_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_narrow_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_wide_round": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_round_wide_facialhair_beardandmustache":      { x: 0, y: -1.195, z: -0.0065 },
        "ava_head_round_wide_facialhair_shortBeardandmustache": { x: 0, y: -1.189, z: -0.012 },
        "ava_head_round_wide_facialhair_ThinBeard":             { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_round_wide_facialhair_ThinBeardandmustache":  { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_round_wide_facialhair_Thinmustache":          { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_round_both_facialhair_archedmustache":        { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_both_facialhair_downwardsmustache":     { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_wide_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_wide_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_round_wide_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_round_wide_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_narrow_square": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_square_narrow_facialhair_beardandmustache":      { x: 0, y: -1.195, z: -0.0054 },
        "ava_head_square_narrow_facialhair_shortBeardandmustache": { x: 0, y: -1.190, z: -0.008 },
        "ava_head_square_narrow_facialhair_ThinBeard":             { x: 0, y: -1.194, z: -0.006 },
        "ava_head_square_narrow_facialhair_ThinBeardandmustache":  { x: 0, y: -1.194, z: -0.006 },
        "ava_head_square_narrow_facialhair_Thinmustache":          { x: 0, y: -1.194, z: -0.006 },
        "ava_head_square_both_facialhair_archedmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_both_facialhair_downwardsmustache":       { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_narrow_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_narrow_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_square_narrow_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_narrow_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_wide_square": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_square_wide_facialhair_beardandmustache":      { x: 0, y: -1.195, z: -0.0065 },
        "ava_head_square_wide_facialhair_shortBeardandmustache": { x: 0, y: -1.190, z: -0.010 },
        "ava_head_square_wide_facialhair_ThinBeard":             { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_square_wide_facialhair_ThinBeardandmustache":  { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_square_wide_facialhair_Thinmustache":          { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_square_both_facialhair_archedmustache":        { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_both_facialhair_downwardsmustache":     { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_wide_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_wide_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_square_wide_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_square_wide_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_narrow_rectangle": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_rectangle_narrow_facialhair_beardandmustache":      { x: 0, y: -1.194, z: -0.0044 },
        "ava_head_rectangle_narrow_facialhair_shortBeardandmustache": { x: 0, y: -1.190, z: -0.006 },
        "ava_head_rectangle_narrow_facialhair_ThinBeard":             { x: 0, y: -1.195, z: -0.006 },
        "ava_head_rectangle_narrow_facialhair_ThinBeardandmustache":  { x: 0, y: -1.195, z: -0.006 },
        "ava_head_rectangle_narrow_facialhair_Thinmustache":          { x: 0, y: -1.195, z: -0.006 },
        "ava_head_rectangle_both_facialhair_archedmustache":          { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_both_facialhair_downwardsmustache":       { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_narrow_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_narrow_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_rectangle_narrow_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_narrow_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
    "ava_head_wide_rectangle": {
        "Ava_Dot": {x:0, y: -0.199, z: 0},
        "ava_head_rectangle_wide_facialhair_beardandmustache":      { x: 0, y: -1.195, z: -0.0065 },
        "ava_head_rectangle_wide_facialhair_shortBeardandmustache": { x: 0, y: -1.190, z: -0.01 },
        "ava_head_rectangle_wide_facialhair_ThinBeard":             { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_rectangle_wide_facialhair_ThinBeardandmustache":  { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_rectangle_wide_facialhair_Thinmustache":          { x: 0, y: -1.198, z: -0.0065 },
        "ava_head_rectangle_both_facialhair_archedmustache":        { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_both_facialhair_downwardsmustache":     { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_wide_facialhair_fullbeard":             { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_wide_facialhair_goatee":                { x: 0, y: -1.197, z: -0.004 },
        "ava_head_rectangle_wide_facialhair_mustache":              { x: 0, y: -1.192, z: -0.004 },
        "ava_head_rectangle_wide_facialhair_bandholz":              { x: 0, y: -1.192, z: -0.004 },
    },
};

const hairPositionAdjustments = {
    "Ava_Dot": {x:0, y: -0.199, z: 0},
    "ava_head_narrow_oblong":    { x: 0, y: -1.182, z: -0.02 },
    "ava_head_wide_oblong":      { x: 0, y: -1.178, z: -0.007 },
    "ava_head_narrow_teardrop":  { x: 0, y: -1.182, z: -0.02 },
    "ava_head_wide_teardrop":    { x: 0, y: -1.182, z: -0.02 },
    "ava_head_narrow_kite":      { x: 0, y: -1.182, z: -0.04 },
    "ava_head_wide_kite":        { x: 0, y: -1.180, z: -0.025 },
    "ava_head_narrow_round":     { x: 0, y: -1.182, z: -0.02 },
    "ava_head_wide_round":       { x: 0, y: -1.175, z: -0.025 },
    "ava_head_narrow_square":    { x: 0, y: -1.182, z: -0.01 },
    "ava_head_wide_square":      { x: 0, y: -1.182, z: -0.01 },
    "ava_head_narrow_rectangle": { x: 0, y: -1.182, z: -0.01 },
    "ava_head_wide_rectangle":   { x: 0, y: -1.182, z: -0.015 },
};

const nosePositionAdjustments = {
    // Narrow Oblong Heads
    "ava_head_narrow_oblong": {
        "ava_head_oblong_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_oblong_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_oblong_nose_grecian":  { x: 0, y: -1.190, z: -0.018 },
        "ava_head_oblong_nose_pointed":  { x: 0, y: -1.185, z: -0.017 },
        "ava_head_oblong_nose_roman":    { x: 0, y: -1.185, z: -0.018 },
        "ava_head_oblong_nose_snub":     { x: 0, y: -1.190, z: -0.018 },
    },
    // Wide Oblong Heads
    "ava_head_wide_oblong": {
        // Same as narrow oblong
        // You can copy the same entries or reference the narrow oblong mapping
        "ava_head_oblong_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_oblong_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_oblong_nose_grecian":  { x: 0, y: -1.185, z: -0.022 },
        "ava_head_oblong_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_oblong_nose_roman":    { x: 0, y: -1.185, z: -0.015 },
        "ava_head_oblong_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
    // Narrow Teardrop Heads
    "ava_head_narrow_teardrop": {
        "ava_head_teardrop_nose_delicate": { x: 0, y: -1.190, z: -0.015 },
        "ava_head_teardrop_nose_droopy":   { x: 0, y: -1.178, z: -0.0154 },
        "ava_head_teardrop_nose_grecian":  { x: 0, y: -1.187, z: -0.019 },
        "ava_head_teardrop_nose_pointed":  { x: 0, y: -1.192, z: -0.016 },
        "ava_head_teardrop_nose_roman":    { x: 0, y: -1.188, z: -0.015 },
        "ava_head_teardrop_nose_snub":     { x: 0, y: -1.185, z: -0.017 },
    },
    // Wide Teardrop Heads
    "ava_head_wide_teardrop": {
        // Same as narrow teardrop
        "ava_head_teardrop_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_teardrop_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_teardrop_nose_grecian":  { x: 0, y: -1.190, z: -0.022 },
        "ava_head_teardrop_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_teardrop_nose_roman":    { x: 0, y: -1.185, z: -0.015 },
        "ava_head_teardrop_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
    // Narrow Kite Heads
    "ava_head_narrow_kite": {
        "ava_head_kite_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_kite_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_kite_nose_grecian":  { x: 0, y: -1.193, z: -0.017 },
        "ava_head_kite_nose_pointed":  { x: 0, y: -1.190, z: -0.015 },
        "ava_head_kite_nose_roman":    { x: 0, y: -1.190, z: -0.016 },
        "ava_head_kite_nose_snub":     { x: 0, y: -1.188, z: -0.014 },
    },
    // Wide Kite Heads
    "ava_head_wide_kite": {
        // Same as narrow kite
        "ava_head_kite_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_kite_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_kite_nose_grecian":  { x: 0, y: -1.190, z: -0.022 },
        "ava_head_kite_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_kite_nose_roman":    { x: 0, y: -1.185, z: -0.015 },
        "ava_head_kite_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
    // Narrow and Wide Round Heads, Narrow and Wide Square Heads
    // Use 'ava_head_round_nose_*' models
    "ava_head_narrow_round": {
        "ava_head_round_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_round_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_round_nose_grecian":  { x: 0, y: -1.190, z: -0.018 },
        "ava_head_round_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_round_nose_roman":    { x: 0, y: -1.190, z: -0.018 },
        "ava_head_round_nose_snub":     { x: 0, y: -1.188, z: -0.019 },
    },
    "ava_head_wide_round": {
        // Same as narrow round
        "ava_head_round_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_round_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_round_nose_grecian":  { x: 0, y: -1.190, z: -0.022 },
        "ava_head_round_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_round_nose_roman":    { x: 0, y: -1.185, z: -0.015 },
        "ava_head_round_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
    "ava_head_narrow_square": {
        // Uses 'ava_head_round_nose_*' models
        "ava_head_round_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_round_nose_droopy":   { x: 0, y: -1.192, z: -0.015 },
        "ava_head_round_nose_grecian":  { x: 0, y: -1.185, z: -0.018 },
        "ava_head_round_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_round_nose_roman":    { x: 0, y: -1.185, z: -0.018 },
        "ava_head_round_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
    "ava_head_wide_square": {
        // Uses 'ava_head_round_nose_*' models
        "ava_head_round_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_round_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_round_nose_grecian":  { x: 0, y: -1.190, z: -0.022 },
        "ava_head_round_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_round_nose_roman":    { x: 0, y: -1.185, z: -0.015 },
        "ava_head_round_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
    // Narrow and Wide Rectangle Heads
    // Use 'ava_head_oblong_nose_*' models
    "ava_head_narrow_rectangle": {
        "ava_head_oblong_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_oblong_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_oblong_nose_grecian":  { x: 0, y: -1.185, z: -0.018 },
        "ava_head_oblong_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_oblong_nose_roman":    { x: 0, y: -1.185, z: -0.018 },
        "ava_head_oblong_nose_snub":     { x: 0, y: -1.185, z: -0.015 },
    },
    "ava_head_wide_rectangle": {
        // Same as narrow rectangle
        "ava_head_oblong_nose_delicate": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_oblong_nose_droopy":   { x: 0, y: -1.192, z: -0.01 },
        "ava_head_oblong_nose_grecian":  { x: 0, y: -1.180, z: -0.022 },
        "ava_head_oblong_nose_pointed":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_oblong_nose_roman":    { x: 0, y: -1.180, z: -0.015 },
        "ava_head_oblong_nose_snub":     { x: 0, y: -1.185, z: -0.018 },
    },
};

const eyePositionAdjustments = {
    // Narrow Oblong Heads
    "ava_head_narrow_oblong": {
        "ava_head_oblong_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_oblong_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_oblong_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_oblong_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_oblong_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_oblong_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    // Wide Oblong Heads
    "ava_head_wide_oblong": {
        // Same as narrow oblong
        "ava_head_oblong_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_oblong_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_oblong_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_oblong_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_oblong_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_oblong_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    // Narrow Teardrop Heads
    "ava_head_narrow_teardrop": {
        "ava_head_teardrop_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_teardrop_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_teardrop_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_teardrop_eyes_v4": { x: 0, y: -1.192, z: -0.015 },
        "ava_head_teardrop_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_teardrop_eyes_v6": { x: 0, y: -1.192, z: -0.015 },
    },
    // Wide Teardrop Heads
    "ava_head_wide_teardrop": {
        // Same as narrow teardrop
        "ava_head_teardrop_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_teardrop_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_teardrop_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_teardrop_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_teardrop_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_teardrop_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    // Narrow Kite Heads
    "ava_head_narrow_kite": {
        "ava_head_kite_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_kite_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_kite_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_kite_eyes_v4": { x: 0, y: -1.191, z: -0.017 },
        "ava_head_kite_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_kite_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    // Wide Kite Heads
    "ava_head_wide_kite": {
        // Same as narrow kite
        "ava_head_kite_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_kite_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_kite_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_kite_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_kite_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_kite_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    // Narrow and Wide Round Heads, Narrow and Wide Square Heads
    // Use 'ava_head_round_eyes_*' models
    "ava_head_narrow_round": {
        "ava_head_round_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_round_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_round_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_round_eyes_v4": { x: 0, y: -1.191, z: -0.016 },
        "ava_head_round_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_round_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    "ava_head_wide_round": {
        // Same as narrow round
        "ava_head_round_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_round_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_round_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_round_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_round_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_round_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    "ava_head_narrow_square": {
        // Uses 'ava_head_round_eyes_*' models
        "ava_head_round_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_round_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_round_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_round_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_round_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_round_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    "ava_head_wide_square": {
        // Uses 'ava_head_round_eyes_*' models
        "ava_head_round_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_round_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_round_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_round_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_round_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_round_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    // Narrow and Wide Rectangle Heads
    // Use 'ava_head_oblong_eyes_*' models
    "ava_head_narrow_rectangle": {
        "ava_head_oblong_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_oblong_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_oblong_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_oblong_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_oblong_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_oblong_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
    "ava_head_wide_rectangle": {
        // Same as narrow rectangle
        "ava_head_oblong_eyes_v1": { x: 0, y: -1.191, z: -0.015 },
        "ava_head_oblong_eyes_v2": { x: 0, y: -1.190, z: -0.014 },
        "ava_head_oblong_eyes_v3": { x: 0, y: -1.190, z: -0.013 },
        "ava_head_oblong_eyes_v4": { x: 0, y: -1.189, z: -0.018 },
        "ava_head_oblong_eyes_v5": { x: 0, y: -1.191, z: -0.014 },
        "ava_head_oblong_eyes_v6": { x: 0, y: -1.191, z: -0.018 },
    },
};

const eyebrowPositionAdjustments = {
    // Narrow Oblong Heads
    "ava_head_narrow_oblong": {
        "ava_head_oblong_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_oblong_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_oblong_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_oblong_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    // Wide Oblong Heads
    "ava_head_wide_oblong": {
        // Same as narrow oblong
        "ava_head_oblong_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_oblong_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_oblong_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_oblong_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    // Narrow Teardrop Heads
    "ava_head_narrow_teardrop": {
        "ava_head_teardrop_eyebrows_arched":       { x: 0, y: -1.199, z: -0.023 },
        "ava_head_teardrop_eyebrows_rounded":      { x: 0, y: -1.199, z: -0.023 },
        "ava_head_teardrop_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_teardrop_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_teardrop_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_teardrop_eyebrows_upward":       { x: 0, y: -1.199, z: -0.020 },
    },
    // Wide Teardrop Heads
    "ava_head_wide_teardrop": {
        // Same as narrow teardrop
        "ava_head_teardrop_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_teardrop_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_teardrop_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_teardrop_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_teardrop_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_teardrop_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    // Narrow Kite Heads
    "ava_head_narrow_kite": {
        "ava_head_kite_eyebrows_arched":       { x: 0, y: -1.190, z: -0.020 },
        "ava_head_kite_eyebrows_rounded":      { x: 0, y: -1.199, z: -0.023 },
        "ava_head_kite_eyebrows_s-shaped":     { x: 0, y: -1.185, z: -0.018 },
        "ava_head_kite_eyebrows_steeparched":  { x: 0, y: -1.185, z: -0.015 },
        "ava_head_kite_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_kite_eyebrows_upward":       { x: 0, y: -1.185, z: -0.015 },
    },
    // Wide Kite Heads
    "ava_head_wide_kite": {
        // Same as narrow kite
        "ava_head_kite_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_kite_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_kite_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_kite_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_kite_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_kite_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    // Narrow and Wide Round Heads
    "ava_head_narrow_round": {
        "ava_head_round_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_round_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_round_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.019 },
        "ava_head_round_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.016 },
        "ava_head_round_eyebrows_straight":     { x: 0, y: -1.179, z: -0.017 },
        "ava_head_round_eyebrows_upward":       { x: 0, y: -1.179, z: -0.017 },
    },
    "ava_head_wide_round": {
        // Same as narrow round
        "ava_head_round_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_round_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_round_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_round_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_round_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_round_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    // Narrow and Wide Square Heads
    "ava_head_narrow_square": {
        // Uses 'ava_head_round_eyebrows_*' models
        "ava_head_round_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_round_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_round_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.017 },
        "ava_head_round_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.014 },
        "ava_head_round_eyebrows_straight":     { x: 0, y: -1.179, z: -0.014 },
        "ava_head_round_eyebrows_upward":       { x: 0, y: -1.179, z: -0.014 },
    },
    "ava_head_wide_square": {
        // Same as narrow square
        "ava_head_round_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_round_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_round_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_round_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_round_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_round_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    // Narrow and Wide Rectangle Heads
    "ava_head_narrow_rectangle": {
        // Uses 'ava_head_oblong_eyebrows_*' models
        "ava_head_oblong_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_oblong_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_oblong_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_oblong_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
    "ava_head_wide_rectangle": {
        // Same as narrow rectangle
        "ava_head_oblong_eyebrows_arched":       { x: 0, y: -1.183, z: -0.023 },
        "ava_head_oblong_eyebrows_rounded":      { x: 0, y: -1.179, z: -0.023 },
        "ava_head_oblong_eyebrows_s-shaped":     { x: 0, y: -1.179, z: -0.018 },
        "ava_head_oblong_eyebrows_steeparched":  { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_straight":     { x: 0, y: -1.179, z: -0.015 },
        "ava_head_oblong_eyebrows_upward":       { x: 0, y: -1.179, z: -0.015 },
    },
};

const lipPositionAdjustments = {
    // Narrow and Wide Oblong Heads
    "ava_head_narrow_oblong": {
        "ava_head_oblong_lips_BowShaped":    { x: 0, y: -1.193, z: -0.0070 },
        "ava_head_oblong_lips_full":         { x: 0, y: -1.188, z: -0.010 },
        "ava_head_oblong_lips_heavylower":   { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_oblong_lips_thin":         { x: 0, y: -1.194, z: -0.010 },
        "ava_head_oblong_lips_v3":           { x: 0, y: -1.190, z: -0.010 },
        "ava_head_oblong_lips_v5":           { x: 0, y: -1.193, z: -0.0086 },
    },
    "ava_head_wide_oblong": {
        // Same as narrow oblong
        // You can copy the same entries or reference the narrow oblong mapping
        "ava_head_oblong_lips_BowShaped":    { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_oblong_lips_full":         { x: 0, y: -1.194, z: -0.012 },
        "ava_head_oblong_lips_heavylower":   { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_oblong_lips_thin":         { x: 0, y: -1.194, z: -0.010 },
        "ava_head_oblong_lips_v3":           { x: 0, y: -1.190, z: -0.010 },
        "ava_head_oblong_lips_v5":           { x: 0, y: -1.193, z: -0.010 },
    },
    // Narrow and Wide Teardrop Heads
    "ava_head_narrow_teardrop": {
        "ava_head_teardrop_lips_BowShaped":  { x: 0, y: -1.190, z: -0.0080 },
        "ava_head_teardrop_lips_full":       { x: 0, y: -1.192, z: -0.010 },
        "ava_head_teardrop_lips_heavylower": { x: 0, y: -1.192, z: -0.011 },
        "ava_head_teardrop_lips_thin":       { x: 0, y: -1.194, z: -0.0080 },
        "ava_head_teardrop_lips_v3":         { x: 0, y: -1.190, z: -0.0088 },
        "ava_head_teardrop_lips_v5":         { x: 0, y: -1.193, z: -0.0085 },
    },
    "ava_head_wide_teardrop": {
        // Same as narrow teardrop
        "ava_head_teardrop_lips_BowShaped":  { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_teardrop_lips_full":       { x: 0, y: -1.194, z: -0.012 },
        "ava_head_teardrop_lips_heavylower": { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_teardrop_lips_thin":       { x: 0, y: -1.194, z: -0.010 },
        "ava_head_teardrop_lips_v3":         { x: 0, y: -1.190, z: -0.010 },
        "ava_head_teardrop_lips_v5":         { x: 0, y: -1.193, z: -0.010 },
    },
    // Narrow and Wide Kite Heads
    "ava_head_narrow_kite": {
        "ava_head_kite_lips_BowShaped":      { x: 0, y: -1.196, z: -0.010 },
        "ava_head_kite_lips_full":           { x: 0, y: -1.194, z: -0.008 },
        "ava_head_kite_lips_heavylower":     { x: 0, y: -1.192, z: -0.0089 },
        "ava_head_kite_lips_thin":           { x: 0, y: -1.199, z: -0.010 },
        "ava_head_kite_lips_v3":             { x: 0, y: -1.190, z: -0.0078 },
        "ava_head_kite_lips_v5":             { x: 0, y: -1.193, z: -0.0078 },
    },
    "ava_head_wide_kite": {
        // Same as narrow kite
        "ava_head_kite_lips_BowShaped":      { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_kite_lips_full":           { x: 0, y: -1.194, z: -0.012 },
        "ava_head_kite_lips_heavylower":     { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_kite_lips_thin":           { x: 0, y: -1.194, z: -0.010 },
        "ava_head_kite_lips_v3":             { x: 0, y: -1.190, z: -0.010 },
        "ava_head_kite_lips_v5":             { x: 0, y: -1.193, z: -0.010 },
    },
    // Narrow and Wide Round and Square Heads
    "ava_head_narrow_round": {
        "ava_head_round_lips_BowShaped":     { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_round_lips_full":          { x: 0, y: -1.194, z: -0.012 },
        "ava_head_round_lips_heavylower":    { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_round_lips_thin":          { x: 0, y: -1.194, z: -0.010 },
        "ava_head_round_lips_v3":            { x: 0, y: -1.190, z: -0.010 },
        "ava_head_round_lips_v5":            { x: 0, y: -1.193, z: -0.010 },
    },
    "ava_head_wide_round": {
        // Same as narrow round
        "ava_head_round_lips_BowShaped":     { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_round_lips_full":          { x: 0, y: -1.194, z: -0.012 },
        "ava_head_round_lips_heavylower":    { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_round_lips_thin":          { x: 0, y: -1.194, z: -0.010 },
        "ava_head_round_lips_v3":            { x: 0, y: -1.190, z: -0.010 },
        "ava_head_round_lips_v5":            { x: 0, y: -1.193, z: -0.010 },
    },
    "ava_head_narrow_square": {
        // Uses 'ava_head_round_lips_*' models
        "ava_head_round_lips_BowShaped":     { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_round_lips_full":          { x: 0, y: -1.194, z: -0.011 },
        "ava_head_round_lips_heavylower":    { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_round_lips_thin":          { x: 0, y: -1.194, z: -0.010 },
        "ava_head_round_lips_v3":            { x: 0, y: -1.190, z: -0.009 },
        "ava_head_round_lips_v5":            { x: 0, y: -1.193, z: -0.010 },
    },
    "ava_head_wide_square": {
        // Same as narrow square
        "ava_head_round_lips_BowShaped":     { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_round_lips_full":          { x: 0, y: -1.194, z: -0.012 },
        "ava_head_round_lips_heavylower":    { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_round_lips_thin":          { x: 0, y: -1.194, z: -0.010 },
        "ava_head_round_lips_v3":            { x: 0, y: -1.190, z: -0.010 },
        "ava_head_round_lips_v5":            { x: 0, y: -1.193, z: -0.010 },
    },
    // Narrow and Wide Rectangle Heads
    "ava_head_narrow_rectangle": {
        // Uses 'ava_head_oblong_lips_*' models
        "ava_head_oblong_lips_BowShaped":    { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_oblong_lips_full":         { x: 0, y: -1.194, z: -0.012 },
        "ava_head_oblong_lips_heavylower":   { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_oblong_lips_thin":         { x: 0, y: -1.194, z: -0.010 },
        "ava_head_oblong_lips_v3":           { x: 0, y: -1.190, z: -0.010 },
        "ava_head_oblong_lips_v5":           { x: 0, y: -1.193, z: -0.010 },
    },
    "ava_head_wide_rectangle": {
        // Same as narrow rectangle
        "ava_head_oblong_lips_BowShaped":    { x: 0, y: -1.191, z: -0.0083 },
        "ava_head_oblong_lips_full":         { x: 0, y: -1.194, z: -0.012 },
        "ava_head_oblong_lips_heavylower":   { x: 0, y: -1.192, z: -0.0079 },
        "ava_head_oblong_lips_thin":         { x: 0, y: -1.194, z: -0.010 },
        "ava_head_oblong_lips_v3":           { x: 0, y: -1.190, z: -0.010 },
        "ava_head_oblong_lips_v5":           { x: 0, y: -1.193, z: -0.010 },
    },
};

const headDefaults = {
    "ava_head_narrow_oblong": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_oblong_narrow_hair_messy",
        currentNose: "ava_head_oblong_nose_delicate",
        currentEye: "ava_head_oblong_eyes_v1",
        currentEyebrow: "ava_head_oblong_eyebrows_arched",
        currentLip: "ava_head_oblong_lips_BowShaped",
    },
    "ava_head_narrow_teardrop": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_teardrop_narrow_hair_messy",
        currentNose: "ava_head_teardrop_nose_delicate",
        currentEye: "ava_head_teardrop_eyes_v1",
        currentEyebrow: "ava_head_teardrop_eyebrows_arched",
        currentLip: "ava_head_teardrop_lips_BowShaped",
    },
    "ava_head_narrow_kite": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_kite_narrow_hair_messy",
        currentNose: "ava_head_kite_nose_delicate",
        currentEye: "ava_head_kite_eyes_v1",
        currentEyebrow: "ava_head_kite_eyebrows_arched",
        currentLip: "ava_head_kite_lips_BowShaped",
    },
    "ava_head_narrow_round": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_round_narrow_hair_longstraight",
        currentNose: "ava_head_round_nose_delicate",
        currentEye: "ava_head_round_eyes_v1",
        currentEyebrow: "ava_head_round_eyebrows_arched",
        currentLip: "ava_head_round_lips_BowShaped",
    },
    "ava_head_narrow_square": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_square_narrow_hair_longstraight",
        currentNose: "ava_head_round_nose_delicate", // Note: Uses "round" nose model
        currentEye: "ava_head_round_eyes_v1",        // Note: Uses "round" eye model
        currentEyebrow: "ava_head_round_eyebrows_arched",
        currentLip: "ava_head_round_lips_BowShaped",
    },
    "ava_head_narrow_rectangle": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_rectangle_narrow_hair_longstraight",
        currentNose: "ava_head_oblong_nose_delicate",
        currentEye: "ava_head_oblong_eyes_v1",
        currentEyebrow: "ava_head_oblong_eyebrows_arched",
        currentLip: "ava_head_oblong_lips_BowShaped",
    },
    "ava_head_wide_oblong": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_oblong_wide_hair_messy",
        currentNose: "ava_head_oblong_nose_delicate",
        currentEye: "ava_head_oblong_eyes_v1",
        currentEyebrow: "ava_head_oblong_eyebrows_arched",
        currentLip: "ava_head_oblong_lips_BowShaped",
    },
    "ava_head_wide_teardrop": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_teardrop_wide_hair_messy",
        currentNose: "ava_head_teardrop_nose_delicate",
        currentEye: "ava_head_teardrop_eyes_v1",
        currentEyebrow: "ava_head_teardrop_eyebrows_arched",
        currentLip: "ava_head_teardrop_lips_BowShaped",
    },
    "ava_head_wide_kite": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_kite_wide_hair_messy",
        currentNose: "ava_head_kite_nose_delicate",
        currentEye: "ava_head_kite_eyes_v1",
        currentEyebrow: "ava_head_kite_eyebrows_arched",
        currentLip: "ava_head_kite_lips_BowShaped",
    },
    "ava_head_wide_round": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_round_wide_hair_longstraight",
        currentNose: "ava_head_round_nose_delicate",
        currentEye: "ava_head_round_eyes_v1",
        currentEyebrow: "ava_head_round_eyebrows_arched",
        currentLip: "ava_head_round_lips_BowShaped",
    },
    "ava_head_wide_square": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_square_wide_hair_longstraight",
        currentNose: "ava_head_round_nose_delicate", // Note: Uses "round" nose model
        currentEye: "ava_head_round_eyes_v1",        // Note: Uses "round" eye model
        currentEyebrow: "ava_head_round_eyebrows_arched",
        currentLip: "ava_head_round_lips_BowShaped",
    },
    "ava_head_wide_rectangle": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_rectangle_wide_hair_longstraight",
        currentNose: "ava_head_oblong_nose_delicate",
        currentEye: "ava_head_oblong_eyes_v1",
        currentEyebrow: "ava_head_oblong_eyebrows_arched",
        currentLip: "ava_head_oblong_lips_BowShaped",
    },
};


class MainStage {
    constructor() {
        this.camera = null;
        this.scene = null;
        this.renderer = null;
        this.controls = null;
        this.loader = null;
        this.selected = null;
        this.meshColors = {};
        this.currentBeard = null;
        this.currentHair = null;
        this.currentNose = null;
        this.currentEye = null;
        this.currentEyebrow = null;
        this.currentLip = null;
        this.selectedColor = {r: 0.1, g: 0.1, b: 0.1};
        this.skinColor = null;
        this.group = new Group();
        this.init();
        this.group.name = "avatarModel"

        //This keeps track of every mesh on the viewport
        this.loadedMeshes = {
            Torso: {
                name: "ava_body_male_type1_normal_colarshirt",
                rotation: {x: 0, y: 0, z: 0}
            },
            Head: {
                name: "ava_head_narrow_oblong",
                rotation: {x: 0, y: 0, z: 0}
            },
            FootR: {
                name: "shoes_v2_R",
                rotation: {x: 0, y: 0, z: 0}
            }
        };

        // List of information on the meshes (attach points, body groups, etc...)
        this.meshStaticInfo = {
            Torso: {
                partCategory: "torso",
                parentAttachment: "Head_Neck",
                childAttachment: "Torso_Neck"
            },
            Head: {
                partCategory: "head",
                parentAttachment: "Head_Neck",
                childAttachment: "Head_Head"
            },
            Beard: {
                partCategory: "beard",
                parentAttachment: "Head_Neck",
                childAttachment: undefined
            },
            Hair: {
                partCategory: "hair",
                parentAttachment: "Head_Neck",
                childAttachment: undefined
            },
            Nose: {
                partCategory: "nose",
                parentAttachment: "Head_Neck",
                childAttachment: undefined
            },
            Lip: {
                partCategory: "lip",
                parentAttachment: "Head_Neck",
                childAttachment: undefined
            },
            Eyebrow: {
                partCategory: "eyebrow",
                parentAttachment: "Head_Neck",
                childAttachment: undefined
            },
            Eye: {
                partCategory: "eye",
                parentAttachment: "Head_Neck",
                childAttachment: undefined
            },
            FootR: {
                partCategory: "foot",
                parentAttachment: "Torso_Neck",
                childAttachment: undefined
            }
        };

        // List of parent/child relations
        this.childrenList = {
            Torso: ["Head", "FootR"],
        };

        this.link = document.createElement("a");
        this.link.style.display = "none";
        document.body.appendChild(this.link);

        document.body.onresize = function () {
            //size of viewport
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            //aspect ratio update
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
        }.bind(this);

        // Expose global flags
        window.loaded = false;
        window.partloaded = false;

        // Expose global functions
        window.changeStand = this.changeStand.bind(this);
        window.loadDefaultMeshes = this.loadDefaultMeshes.bind(this);
        window.changeMesh = this.changeMesh.bind(this);
        window.selectedMesh = this.selectedMesh.bind(this);
        window.getRotation = this.getRotation.bind(this);
        window.changeRotation = this.changeRotation.bind(this);
        // window.loadPose = this.loadPose.bind(this);
        window.exportToSTL = this.exportToSTL.bind(this);
    }

    changeColor(item, chosenColor) {
        this.selectedColor = chosenColor;
        const normalizedColor = new Color(chosenColor.r, chosenColor.g, chosenColor.b);
    
        // Store the color for future reference
        this.meshColors[item] = normalizedColor;
        // If changing the nose or head, update skin materials
        if (item === "mesh-stand")
        return;
    
        if (item === "mesh-head" || item === "NoseModel") {
            // Change all materials named "Skin"
            this.group.traverse((child) => {
                if (child instanceof Mesh && child.material.name === "Skin") {
                    child.material.color.copy(normalizedColor);
                }
            });
        }
    
        // Change the color of the specified item
        this.group.traverse((child) => {
            if (child instanceof Mesh && child.name === item) {
                if (child.material && child.material.color) {
                    child.material.color.copy(normalizedColor);
                }
            }
        });
    }
    
     
    defaultMeshColors(item, chosenColor) {
        let mesh = item === "pose" ? this.group : this.scene.getObjectByName(item);
        mesh.traverse(function (child) {
            if (child instanceof Mesh) {
                if (child.material) {
                    child.material.color.r = chosenColor.r;
                    child.material.color.g = chosenColor.g;
                    child.material.color.b = chosenColor.b;
                }
            }
        });
    };

    createGradientTexture() {
        const canvas = document.createElement('canvas');
        canvas.width = 1920;
        canvas.height = 1024;
        const context = canvas.getContext('2d');
    
        // Create a linear gradient from top (purplish) to bottom (whitish)
        const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
        
        gradient.addColorStop(0, '#80154F'); // Purplish color at the top (0%)
        gradient.addColorStop(0.5, '#80154F'); // Purplish color at 30%
        gradient.addColorStop(1, '#8FAFCB'); // Whitish color at the bottom (100%)
    
        // Apply the gradient to the entire canvas
        context.fillStyle = gradient;
        context.fillRect(0, 0, canvas.width, canvas.height);
    
        const texture = new CanvasTexture(canvas);
        texture.needsUpdate = true;
    
        return texture;
    }

    setGradientBackground(scene) {
        const texture = this.createGradientTexture();
        scene.background = texture;
    };
    
    cleanupScene() {
        // Dispose of all objects in the scene
        this.scene.traverse(object => {
            if (object.geometry) object.geometry.dispose();
            if (object.material) {
                // Dispose of material textures if they exist
                if (object.material.map) object.material.map.dispose();
                object.material.dispose();
            }
        });
    
        // Remove the renderer's DOM element
        if (this.renderer && this.renderer.domElement) {
            this.renderer.domElement.remove();
        }
    
        // Nullify references
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.controls = null;
    };

    navigateToAnotherPage() {
        // Call the cleanup function
        this.cleanupScene();
    
        // Navigate to the new page
        window.location.href = 'newPage.html';
    };

    init() {
        this.loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath('https://mamaar.ai/draco/');
        this.loader.setDRACOLoader(dracoLoader);
        this.scene = new Scene();
        this.scene.name = 'scene';
        this.setGradientBackground(this.scene);
        this.scene.fog = new Fog(0x222222, -5, 15);
        this.scene.add(this.group);

        this.buildCamera();
        this.buildRenderer();
        this.buildControls();
        this.buildLights();
        this.buildFloor();
        this.animate();
    }

    buildDevHelper() {
        // build Axes
        let axes = new AxesHelper(10);
        axes.name = "axes";
        this.scene.add(axes);

        // build Grid
        let size = 50;
        let divisions = 60;
        let colorCenterLine = 0x999999;
        let colorGrid = 0xffffff;
        let grid = new GridHelper(size, divisions, colorCenterLine, colorGrid);
        grid.name = "grid";
        this.scene.add(grid);

        // expose scene to DOM
        window.scene = this.scene
        window.THREE = THREE
    }

    buildCamera() {
        this.camera = new PerspectiveCamera(
            45,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        this.camera.name = 'camera';
        this.camera.position.set(-1, 2, 2);
    }

    buildRenderer() {
        this.renderer = new WebGLRenderer({ antialias: true });
        this.renderer.outputColorSpace = SRGBColorSpace; // Updated line
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = PCFSoftShadowMap;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(this.renderer.domElement);
    }
      

    buildControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.target.set(0, 0, 0);
        this.controls.minDistance = 2;
        this.controls.maxDistance = 5;
        this.controls.minPolarAngle = 0;
        this.controls.maxPolarAngle = Math.PI;
        this.controls.enablePan = false;
        this.controls.enableZoom = true;
    }

    buildLights() {
        let pi = Math.PI;
        let hemisphereLight = new HemisphereLight(0xffffff, 0x444444, pi);
        hemisphereLight.position.set(0, 200, 0);
        this.scene.add(hemisphereLight);

        let dirLight = new DirectionalLight(0xffffff, pi);
        dirLight.position.set(0 , 10, 10);
        dirLight.castShadow = false;
        this.scene.add(dirLight);
            
    }

    buildFloor() {
        //Create a plane that receives shadows (but does not cast them)
        let planeGeometry = new PlaneGeometry(2000, 2000);
        planeGeometry.rotateX( - Math.PI / 2 );

        let planeMaterial = new ShadowMaterial();
        planeMaterial.opacity = 0.2;

        const  plane = new Mesh(planeGeometry, planeMaterial);
        plane.name = "plane";
        plane.rotation.x = -Math.PI / 2;
        plane.position.y = 0;
        plane.receiveShadow = true;
        this.scene.add(plane);
    }

    renderScene() {
        this.camera.lookAt(new Vector3(0, 1, 0));
        this.renderer.render(this.scene, this.camera);
    }

    animate() {
        requestAnimationFrame(this.animate.bind(this));
        this.controls.update();
        TWEEN.update();
        this.renderScene();
    }

    dispose() {
        cancelAnimationFrame(this.animationId);

        window.removeEventListener('resize', this.onWindowResize);

        this.scene.traverse(object => {
            if (!object.isMesh) return;

            object.geometry.dispose();

            if (Array.isArray(object.material)) {
                object.material.forEach(material => material.dispose());
            } else {
                object.material.dispose();
            }
        });

        this.renderer.dispose();

        this.renderer.domElement.remove();
    }

    clearPosition(item) {
        // This function is used to clear the position of an imported glTF file
        item.position.x = 0;
        item.position.y = 0;
        item.position.z = 0;
    }

    rotateElement(item, clearRotation, rotation) {
        console.log('rotateElement called with:', { item, clearRotation, rotation });

        // Validate 'item'
        if (!item) {
            console.error('rotateElement Error: "item" is undefined or null.');
            return;
        }

        // Validate 'item.rotation'
        if (!item.rotation) {
            console.error(`rotateElement Error: "item.rotation" is undefined for "${item.name}".`);
            return;
        }

        if (clearRotation === true) {
            // Clear rotation
            item.rotation.x = 0;
            item.rotation.y = 0;
            item.rotation.z = 0;
            console.log(`Cleared rotation for "${item.name}".`);
        } else {
            // Validate 'rotation'
            if (
                !rotation ||
                typeof rotation.x !== 'number' ||
                typeof rotation.y !== 'number' ||
                typeof rotation.z !== 'number'
            ) {
                console.error(
                    `rotateElement Error: Invalid "rotation" parameter for "${item.name}":`,
                    rotation
                );
                return;
            }

            // Apply rotation
            item.rotation.x = rotation.x;
            item.rotation.y = rotation.y;
            item.rotation.z = rotation.z;
            console.log(`Applied rotation to "${item.name}":`, rotation);
        }
    }

    placeMesh(
        meshFileName,
        partCategory,
        meshType,
        parentAttachment,
        childAttachment,
        rotation,
        isFirstLoad,
        bones,
        poseData
    ) {
        // Check if meshType is allowed
        //if (!allowedMeshTypes.includes(meshType)) {
        //    console.warn(`placeMesh: Skipping unknown meshType "${meshType}".`);
        //    return;
        //}
    
        // Validate essential parameters
        if (!meshFileName || !partCategory) {
            console.error(`placeMesh Error: Missing meshFileName or partCategory for meshType "${meshType}".`);
            return;
        }
    
        const modelPath = `./models/${partCategory}/${meshFileName}.glb`;
        console.log(`placeMesh: Loading meshType "${meshType}" from "${modelPath}".`);
    
        this.loader.load(
            modelPath,
            glTF => {
                console.log(`placeMesh: Successfully loaded "${meshType}".`);
                let loadedContentRoot = glTF.scene.children[0];
                loadedContentRoot.name = meshType;
    
                loadedContentRoot.traverse((child) => {
                    if (child instanceof Mesh) {
                        child.name = `mesh-${meshType.toLowerCase()}`;
                        child.castShadow = true;
                        child.receiveShadow = true;
                        console.log(`placeMesh: Configured mesh "${child.name}".`);
    
                        // Reapply stored color if exists
                        const storedColor = this.meshColors[child.name];
                        if (storedColor) {
                            child.material.color.set(storedColor);
                            console.log(`placeMesh: Reapplied stored color to "${child.name}".`);
                        }
                    }
                });
    
                if(meshType === "Torso")
                {
                    console.log("place mesh: Torso rotation", this.loadedMeshes["Torso"].rotation)
                }
                // Add to group and update matrices
                this.group.add(loadedContentRoot);
                this.scene.updateMatrixWorld(true);
    
                // Assign to loadedMeshes
                this.loadedMeshes[meshType] = loadedContentRoot;
                this.loadedMeshes[meshType].meshFileName = meshFileName;
    
                // Set rotation
                if (rotation) {
                    if (typeof rotation === 'object') {
                        if ('x' in rotation && 'y' in rotation && 'z' in rotation) {
                            loadedContentRoot.rotation.set(rotation.x, rotation.y, rotation.z);
                            console.log(`placeMesh: Applied full rotation to "${meshType}":`, rotation);
                        } else {
                            // Partial rotation
                            if ('x' in rotation) {
                                loadedContentRoot.rotation.x = rotation.x;
                                console.log(`placeMesh: Applied x-rotation to "${meshType}": ${rotation.x}`);
                            }
                            if ('y' in rotation) {
                                loadedContentRoot.rotation.y = rotation.y;
                                console.log(`placeMesh: Applied y-rotation to "${meshType}": ${rotation.y}`);
                            }
                            if ('z' in rotation) {
                                loadedContentRoot.rotation.z = rotation.z;
                                console.log(`placeMesh: Applied z-rotation to "${meshType}": ${rotation.z}`);
                            }
                        }
                    } else if (typeof rotation === 'number') {
                        // Assuming rotation is for Y-axis
                        loadedContentRoot.rotation.y = rotation;
                        console.log(`placeMesh: Applied y-rotation to "${meshType}": ${rotation}`);
                    } else {
                        console.warn(`placeMesh: Invalid rotation parameter for "${meshType}":`, rotation);
                    }
                } else {
                    console.log(`placeMesh: No rotation applied to "${meshType}".`);
                }
    
                // Apply pose data if available
                if (poseData) {
                    loadedContentRoot.traverse((child) => {
                        if (child instanceof Bone && poseData[child.name]) {
                            const pose = poseData[child.name];
                            window.changeRotation(child.name, pose.x, "x");
                            window.changeRotation(child.name, pose.y, "y");
                            window.changeRotation(child.name, pose.z, "z");
                            console.log(`placeMesh: Applied pose to bone "${child.name}":`, pose);
                        }
                    });
                }
    
                // Handle parent-child attachment if provided
                console.log(`placeMesh: parentAttachment="${parentAttachment}", childAttachment="${childAttachment}", mesh-type=" ${meshType}"`);
    
                if (parentAttachment && childAttachment) {
                    let targetBone = this.scene.getObjectByName(parentAttachment);
                    let object = this.scene.getObjectByName(childAttachment);
                    if (targetBone && object) {
                        if (targetBone !== object) {
                            this.clearPosition(object);
                            this.rotateElement(object, true);
                            this.rotateElement(object, false, rotation);
                            targetBone.add(object);
                        } else {
                            console.warn(`Cannot add object "${object.name}" as a child of itself.`);
                        }
                    } else {
                        console.warn(`Attachment failed: parent "${parentAttachment}" or child "${childAttachment}" not found.`);
                    }
                }
    
                // Refresh child meshes if any
                let children = this.childrenList[meshType];
                if (children && isFirstLoad) {
                    for (const child of children) {
                        console.log(`Calling Child : ${child}` )
                        this.refreshMesh(child, isFirstLoad, bones, poseData);
                    }
                }
    
                // Additional logic for specific mesh types
                if (meshType === "FootR") {
                    if (this.scene.getObjectByName("Torso_Hip")) {
                        this.scene.updateMatrixWorld();
                        this.placeStand();
                        console.log(`placeMesh: Processed "FootR" and placed stand.`);
                    }
                }
    
                console.log(`placeMesh: Completed loading "${meshType}".`);
    
                // Apply default accessories for the head
                if (meshType === "Head") {
                    const headMesh = this.loadedMeshes.Head;
                    if (headMesh && headDefaults[headMesh.meshFileName]) {
                        console.log("meshfilename", meshFileName)
                        const defaults = headDefaults[headMesh.meshFileName];
                        this.currentBeard = defaults.currentBeard;
                        this.currentHair = defaults.currentHair;
                        this.currentNose = defaults.currentNose;
                        this.currentEye = defaults.currentEye;
                        this.currentEyebrow = defaults.currentEyebrow;
                        this.currentLip = defaults.currentLip;
                        console.log(`placeMesh: Loaded defaults for head "${headMesh.meshFileName}".`);
    
                        // Apply accessories
                        this.applyBeard(this.currentBeard);
                        this.applyHair(this.currentHair);
                        this.applyNose(this.currentNose);
                        this.applyEye(this.currentEye);
                        this.applyEyebrow(this.currentEyebrow);
                        this.applyLip(this.currentLip);
                    } else {
                        console.warn(`placeMesh: No defaults found for head mesh "${headMesh?.meshFileName || 'undefined'}".`);
                    }
                }
    
                window.partloaded = true;
            },
            xhr => {
                // Progress Callback
                console.log(`placeMesh: Loading "${meshType}": ${(xhr.loaded / xhr.total * 100).toFixed(2)}% loaded`);
            },
            error => {
                console.error(`placeMesh: Error loading mesh "${meshType}" from meshFile "${meshFileName}" and path "${modelPath}":`, error);
            }
        );
    }
    
    
    refreshMesh(meshType, isFirstLoad, bones, poseData) {
        const mesh = this.group.getObjectByName(meshType);
        this.group.remove(mesh);

        console.log(`REFRESH ${meshType}: ${this.loadedMeshes[meshType].rotation}`)
        this.placeMesh(
            this.loadedMeshes[meshType].name,
            this.meshStaticInfo[meshType].partCategory,
            meshType,
            this.meshStaticInfo[meshType].parentAttachment,
            this.meshStaticInfo[meshType].childAttachment,
            this.loadedMeshes[meshType].rotation,
            isFirstLoad,
            bones,
            poseData
        );
    }

    loadStand(stand) {
        console.log("Load Stand")
        let minFinder = new MinGeometryFinder();
        this.loader.load(
            "./models/stand/" + stand + ".glb",
            glTF => {
                console.log('glTF:', glTF);
                console.log('glTF.scene:', glTF.scene);
                console.log('glTF.scene.children:', glTF.scene.children);
    
                let loadedContentRoot = glTF.scene.children[0]; // Use glTF.scene directly
                console.log("loadstand- meshtype:", loadedContentRoot.name);
                if (loadedContentRoot) {
                    loadedContentRoot.traverse((child) => {
                        if (child instanceof THREE.Mesh) {
                            child.name = "mesh-stand";
                            child.castShadow = true;
                            child.receiveShadow = true;
                            let normalizedColor = {
                                r: child.material.color.r,
                                g: child.material.color.g,
                                b: child.material.color.b
                            };
                            child.material.color.set(normalizedColor);
                        }
                    });
                } else {
                    console.error('No loadedContentRoot found in GLTF model.');
                    return;
                }
    
                let footR = this.scene.getObjectByName("FootR");
                if (!footR) {
                    console.error('FootR not found in the scene.');
                    return;
                }
                let resultR = minFinder.parse(footR);
    
                // Default color to all the meshes
                if (loadedContentRoot.material) {
                    loadedContentRoot.material.color = this.selectedColor;
                }
    
                this.group.add(loadedContentRoot);
                console.log("loadstand: loadedcontentroot", loadedContentRoot)
                let torsoHip = this.scene.getObjectByName("Torso_Hip");
                if (torsoHip) {
                    torsoHip.position.y -= resultR;
                } else {
                    console.error('Torso_Hip not found in the scene.');
                }
    
                window.loaded = true;
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }

    placeStand() {
        console.log("place Stand")
        let minFinder = new MinGeometryFinder();

        if (this.scene.getObjectByName("mesh-stand")) {
            let resultR = minFinder.parse(this.scene.getObjectByName("FootR"));
            // let resultL = minFinder.parse(this.scene.getObjectByName("FootL"));
            // let result = resultL > resultR ? resultR : resultL;

            this.scene.getObjectByName("mesh-stand").position.y -= resultR;
        } else {
            this.loadStand("circle");
        }
    }

    changeStand(stand) {

        console.log("change Stand", stand)
        if (this.scene.getObjectByName("mesh-stand")) {
            this.group.remove(this.scene.getObjectByName("mesh-stand"));
            this.loadStand(stand);
        }
    };
     
    applyBeard(beardFileName) {
        this.loader.load(
            "./models/beard/" + beardFileName + ".glb",
            glTF => {
                let beardModel = glTF.scene.children[0];
                // Ensure you're using the correct mesh name
                let headMesh = this.group.getObjectByName("Head_Neck");
                if (headMesh) {
                    // Remove existing beard models
                    for (let i = headMesh.children.length - 1; i >= 0; i--) {
                        if (headMesh.children[i].name === "BeardModel") {
                            headMesh.remove(headMesh.children[i]);
                        }
                    }
                    beardModel.name = "BeardModel";
    
                    // Retrieve position adjustment
                    //const headName = this.loadedMeshes.Head.name;
                    const headName = this.loadedMeshes.Head.meshFileName;
                    const adjustment = beardPositionAdjustments[headName]?.[beardFileName];
                    console.log("Beard", headName, beardFileName)
                    if (adjustment) {
                        beardModel.position.add(new Vector3(adjustment.x, adjustment.y, adjustment.z));
                    } else {
                        console.warn(`No position adjustment found for head "${headName}" and beard "${beardFileName}".`);
                    }
    
                    // Set beard color
                    const beardColor = this.meshColors["BeardModel"];
                    if (beardColor) {
                        beardModel.traverse((child) => {
                            if (child.material) {
                                child.material.color.set(beardColor);
                            }
                        });
                    }
    
                    // Add beard model to head mesh
                    headMesh.add(beardModel);
                    window.partloaded = true;
                } else {
                    console.error("Head_Head mesh not found");
                }
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }
    
    applyHair(HairFileName) {
        this.loader.load(
            "./models/hair/" + HairFileName + ".glb",
            glTF => {
                let HairModel = glTF.scene.children[0];
                // Ensure you're using the correct mesh name
                let headMesh = this.group.getObjectByName("Head_Neck");
                if (headMesh) {
                    // Remove existing hair models
                    for (let i = headMesh.children.length - 1; i >= 0; i--) {
                        if (headMesh.children[i].name === "HairModel") {
                            headMesh.remove(headMesh.children[i]);
                        }
                    }
    
                    HairModel.name = "HairModel"; // Assign a specific name to the hair model
    
    
                    //const headName = this.loadedMeshes.Head.name;
                    const headName = this.loadedMeshes.Head.meshFileName;
                    const adjustment = hairPositionAdjustments[headName];
                    console.log("Hair", headName, HairFileName)

                    if (adjustment) {
                        HairModel.position.add(new Vector3(adjustment.x, adjustment.y, adjustment.z));
                    } else {
                        console.warn(`No position adjustment found for head "${headName}".`);
                    }
    
                    // Set hair color
                    const hairColor = this.meshColors["HairModel"];
                    if (hairColor) {
                        HairModel.traverse((child) => {
                            if (child.material) {
                                child.material.color.set(hairColor);
                            }
                        });
                    }
    
                    // Add hair model to head mesh
                    headMesh.add(HairModel);
                    window.partloaded = true; // Set to true after hair is successfully added
                } else {
                    console.error("Head_Head mesh not found");
                }
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }
       
    applyNose(NoseFileName) {
        this.loader.load(
            "./models/nose/" + NoseFileName + ".glb",
            glTF => {
                let NoseModel = glTF.scene.children[0];
                // Ensure you're using the correct mesh name
                let headMesh = this.group.getObjectByName("Head_Neck");
                if (headMesh) {
                    // Remove existing nose models
                    for (let i = headMesh.children.length - 1; i >= 0; i--) {
                        if (headMesh.children[i].name === "NoseModel") {
                            headMesh.remove(headMesh.children[i]);
                        }
                    }
    
                    NoseModel.name = "NoseModel"; // Assign a specific name to the nose model
    
                        
                    //const headName = this.loadedMeshes.Head.name;
                    const headName = this.loadedMeshes.Head.meshFileName;
                    const adjustment = nosePositionAdjustments[headName]?.[NoseFileName];
                    console.log("Nose", headName, NoseFileName)
                    if (adjustment) {
                        NoseModel.position.add(new Vector3(adjustment.x, adjustment.y, adjustment.z));
                    } else {
                        console.warn(`No position adjustment found for head "${headName}" and nose "${NoseFileName}".`);
                    }
    
                    // Set nose color
                    const noseColor = this.meshColors["NoseModel"];
                    if (noseColor) {
                        NoseModel.traverse((child) => {
                            if (child.material) {
                                child.material.color.set(noseColor);
                            }
                        });
                    }
    
                    // Add nose model to head mesh
                    headMesh.add(NoseModel);
                    window.partloaded = true; // Set to true after nose is successfully added
                } else {
                    console.error("Head_Head mesh not found");
                }
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }
    
    applyEye(EyeFileName) {
        this.loader.load(
            "./models/eye/" + EyeFileName + ".glb",
            glTF => {
                let EyeModel = glTF.scene.children[0];
                // Ensure you're using the correct mesh name
                let headMesh = this.group.getObjectByName("Head_Neck");
                if (headMesh) {
                    // Remove existing eye models
                    for (let i = headMesh.children.length - 1; i >= 0; i--) {
                        if (headMesh.children[i].name === "EyeModel") {
                            headMesh.remove(headMesh.children[i]);
                        }
                    }
    
                    EyeModel.name = "EyeModel"; // Assign a specific name to the eye model
    
                    //const headName = this.loadedMeshes.Head.name;
                    const headName = this.loadedMeshes.Head.meshFileName;
                    const adjustment = eyePositionAdjustments[headName]?.[EyeFileName];
                    console.log("Eye", headName, EyeFileName)
                    if (adjustment) {
                        EyeModel.position.add(new Vector3(adjustment.x, adjustment.y, adjustment.z));
                    } else {
                        console.warn(`No position adjustment found for head "${headName}" and eye "${EyeFileName}".`);
                    }
    
                    // Set eye color (if needed)
                    const eyeColor = this.meshColors["EyeModel"];
                    if (eyeColor) {
                        EyeModel.traverse((child) => {
                            if (child.material) {
                                child.material.color.set(eyeColor);
                            }
                        });
                    }
    
                    // Add eye model to head mesh
                    headMesh.add(EyeModel);
                    window.partloaded = true; // Set to true after eye is successfully added
                } else {
                    console.error("Head_Head mesh not found");
                }
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }

    applyEyebrow(EyeBrowFileName) {
        this.loader.load(
            "./models/eyebrow/" + EyeBrowFileName + ".glb",
            glTF => {
                let EyeBrowModel = glTF.scene.children[0];
                // Ensure you're using the correct mesh name
                let headMesh = this.group.getObjectByName("Head_Neck");
                if (headMesh) {
                    // Remove existing eyebrow models
                    for (let i = headMesh.children.length - 1; i >= 0; i--) {
                        if (headMesh.children[i].name === "EyeBrowModel") {
                            headMesh.remove(headMesh.children[i]);
                        }
                    }
    
                    EyeBrowModel.name = "EyeBrowModel"; // Assign a specific name to the eyebrow model
    
    
                    //const headName = this.loadedMeshes.Head.name;
                    const headName = this.loadedMeshes.Head.meshFileName;
                    const adjustment = eyebrowPositionAdjustments[headName]?.[EyeBrowFileName];
                    console.log("Eyebrow", headName, EyeBrowFileName)
                    if (adjustment) {
                        EyeBrowModel.position.add(new Vector3(adjustment.x, adjustment.y, adjustment.z));
                    } else {
                        console.warn(`No position adjustment found for head "${headName}" and eyebrow "${EyeBrowFileName}".`);
                    }
    
                    // Set eyebrow color (if needed)
                    const eyebrowColor = this.meshColors["EyeBrowModel"];
                    if (eyebrowColor) {
                        EyeBrowModel.traverse((child) => {
                            if (child.material) {
                                child.material.color.set(eyebrowColor);
                            }
                        });
                    }
    
                    // Add eyebrow model to head mesh
                    headMesh.add(EyeBrowModel);
                    window.partloaded = true; // Set to true after eyebrow is successfully added
                } else {
                    console.error("Head_Head mesh not found");
                }
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }
    
    
    applyLip(LipFileName) {
        this.loader.load(
            "./models/lip/" + LipFileName + ".glb",
            glTF => {
                let LipModel = glTF.scene.children[0];
                let headMesh = this.group.getObjectByName("Head_Neck"); // Ensure correct mesh name
                if (headMesh) {
                    // Remove existing Lip models
                    for (let i = headMesh.children.length - 1; i >= 0; i--) {
                        if (headMesh.children[i].name === "LipModel") {
                            headMesh.remove(headMesh.children[i]);
                        }
                    }
    
                    LipModel.name = "LipModel"; // Assign a specific name to the lip model
    
                    //const headName = this.loadedMeshes.Head.name;
                    const headName = this.loadedMeshes.Head.meshFileName;
                    const adjustment = lipPositionAdjustments[headName]?.[LipFileName];
                    console.log("Lip", headName, LipFileName)
                    if (adjustment) {
                        LipModel.position.add(new Vector3(adjustment.x, adjustment.y, adjustment.z));
                    } else {
                        console.warn(`No position adjustment found for head "${headName}" and lip "${LipFileName}".`);
                    }
    
                    // Set Lip color
                    const LipColor = this.meshColors["LipModel"];
                    if (LipColor) {
                        LipModel.traverse((child) => {
                            if (child.material) {
                                child.material.color.set(LipColor);
                            }
                        });
                    }
    
                    // Add Lip model to head mesh
                    headMesh.add(LipModel);
                    window.partloaded = true; // Set to true after Lip is successfully added
                } else {
                    console.error("Head_Head mesh not found");
                }
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }
    

    loadDefaultMeshes(bones, poseData) {
        console.log(`LOAD DEFAULT MESH: ${this.loadedMeshes["Torso"].rotation}`)

        this.placeMesh(
            this.loadedMeshes["Torso"].name,             // meshFileName
            this.meshStaticInfo["Torso"].partCategory,  // partCategory
            "Torso",                                     // meshType
            undefined,                                   // parentAttachment
            undefined,                                   // childAttachment
            this.loadedMeshes["Torso"].rotation,        // rotation
            true,  
            false,                                      // isFirstLoad
            bones,                                       // bones (correctly mapped)
            poseData                                     // poseData (correctly mapped)
        );
    };    

    changeMesh(category, part, isLeft, bones, poseData) {
        window.partloaded = false;
        let meshType;
        let meshFileName;
        let rotation;

        switch (category) {
            case "torso":
                meshFileName = part.file;
                rotation = {x: 0, y: 0, z: 0};
                meshType = "Torso";
                break;
            case "head":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Head";
                break;
            case "beard":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Beard";
                this.currentBeard = meshFileName; 
                this.applyBeard(this.currentBeard);
                break;
            case "hair":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Hair";
                this.currentHair = meshFileName;
                this.applyHair(this.currentHair);
                break;
            case "nose":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Nose";
                this.currentNose = meshFileName;
                this.applyNose(this.currentNose);
                break;
            case "eyebrow":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Eyebrow";
                this.currentEyebrow = meshFileName;
                this.applyEyebrow(this.currentEyebrow);
                break;
            case "eye":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Eye";
                this.currentEye = meshFileName;
                this.applyEye(this.currentEye);
                break;
            case "lip":
                meshFileName = part.file;
                rotation = part.rotation;
                meshType = "Lip";
                this.currentLip = meshFileName;
                this.applyLip(this.currentLip);
                break;    
            case "foot":
                meshType = "FootR";
                meshFileName = part.file[0];
                rotation = part.rotation[0];
                break;
            default:
                meshType = undefined;
        }
        
        if (meshType) {
            let parentAttachmentName = this.meshStaticInfo[meshType].parentAttachment;
            let childAttachmentName = this.meshStaticInfo[meshType].childAttachment;
            let currentMesh = this.group.getObjectByName(meshType);
            let parentAttachmentMesh =
                meshType === "Torso"
                    ? this.scene.getObjectByName("Torso_Hip")
                    : this.group.getObjectByName(parentAttachmentName);
            if (currentMesh) {
                this.group.remove(currentMesh);
                if (parentAttachmentMesh.children) {
                    for (const child of parentAttachmentMesh.children) {
                        if (child instanceof THREE.Bone) {
                            parentAttachmentMesh.remove(child);
                        }
                    }
                }
                const beardColor = this.meshColors[currentMesh];
                if (beardColor) {
                    meshFileName.material.color.set(beardColor);
                };
                this.placeMesh(
                    meshFileName,
                    category,
                    meshType,
                    parentAttachmentName,
                    childAttachmentName,
                    rotation,
                    false,
                    true,
                    bones,
                    poseData
                );
            }
        }
        window.partloaded = true;
        return true;
    };

    selectedMesh(meshName) {
        this.selected = meshName;
        // this.focusOnMesh(meshName);
        // Additional code for handling mesh selection if needed
    };

    getRotation(bone_name) {
        let bone = this.scene.getObjectByName(bone_name);
        if (bone instanceof Bone) {
            return {x: bone.rotation.x, y: bone.rotation.y, z: bone.rotation.z};
        }
    };

    changeRotation(bone_name, value, axis) {
        let bone = this.scene.getObjectByName(bone_name);
        if (bone instanceof Bone) {
            switch (axis) {
                case "x":
                    bone.rotation.x = value;
                    break;
                case "y":
                    bone.rotation.y = value;
                    break;
                case "z":
                    bone.rotation.z = value;
                    break;
                default:
            }
        }
    };

    loadPose(poseData, bones) {
        let L, R = false;
        for (const boneElem of bones) {
            let bone = boneElem.bone;
            window.changeRotation(bone, poseData[bone].x, "x");
            window.changeRotation(bone, poseData[bone].y, "y");
            window.changeRotation(bone, poseData[bone].z, "z");
            this.scene.updateMatrixWorld();

            // if (bone === "LegL_Foot_L") {
            //     L = true;
            //     if (L && R) {
            //         this.placeStand();
            //     }
            // }
            // if (bone === "LegR_Foot_R") {
            //     R = true;
            //     L = true;
            //     if (L && R) {
            //         this.placeStand();
            //     }
            // }
        }
    };

    save(blob, filename) {
        this.link.href = URL.createObjectURL(blob);
        this.link.download = filename || "untitled.json";
        this.link.click();
    }

    saveArrayBuffer(buffer, filename) {
        this.save(new Blob([buffer], {type: "application/octet-stream"}), filename);
    }

    saveString(text, filename) {
        this.save(new Blob([text], {type: "text/plain"}), filename);
    }

    exportToSTL(name) {
        let exporter = new MinSTLExporter();

        if (name) {
            this.saveString(exporter.parse(this.group), name + ".stl");
        } else {
            let stlList = [];
            // I need to know in which order the files are exported...
            let meshes = [
                "mesh-stand",
                "mesh-torso",
                "mesh-arm-l",
                "mesh-arm-r",
                "mesh-foot-l",
                "mesh-foot-r",
                "mesh-hand-l",
                "mesh-hand-r",
                "mesh-head",
                "mesh-leg-l",
                "mesh-leg-r",
                "mesh-neck"
            ];
            for (const mesh of meshes) {
                this.group.traverse(function (child) {
                    if (child.name === mesh) {
                        stlList.push(exporter.parse(child))
                    }
                });
            }

            return stlList;
        }
    };

    
}

export default MainStage;