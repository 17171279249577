import React, { Component } from "react";
import Selector from "./Selector";
import "../css/Category.css";
import faceSubCategory from "../library/faceSubCategory.json";
import colorPalettes from "../library/colorPalettes.json";
import ColorPalletLogo from "../colorpallet.svg"
class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLeft: true,
            selectedColor: null,
            isColorPickerVisible: false,
            currentCategory: this.props.current,
            showFaceSubCategory: false,
        };
    }

    updateLeft = isLeft => {
        this.setState({ isLeft });
    };

    handleColorChange = color => {
        const { mainStage, currentCategory } = this.props;
        const { isLeft } = this.state;
    
        let r = parseInt(color.slice(1, 3), 16) / 255;
        let g = parseInt(color.slice(3, 5), 16) / 255;
        let b = parseInt(color.slice(5, 7), 16) / 255;
    
        const gamma = 2.2;
        const normalizedColor = {
            r: Math.pow(r, gamma),
            g: Math.pow(g, gamma),
            b: Math.pow(b, gamma),
        };
        this.setState({ selectedColor: normalizedColor });
    
        // console.log("c-HandleColorChange = " ,normalizedColor);
        this.setState({ selectedColor: normalizedColor });
        if (mainStage) {
            let meshName;
            switch (currentCategory) {
                case "foot":
                    meshName = isLeft ? "mesh-footl" : "mesh-footr";
                    break;
                case "hair":
                    meshName = "HairModel";
                    break;
                case "beard":
                    meshName = "BeardModel";
                    break;
                case "nose":
                    meshName = "NoseModel";
                    break;
                case "eye":
                    meshName = "EyeModel";
                    break;
                case "eyebrow":
                    meshName = "EyeBrowModel";
                    break;
                case "lip":
                    meshName = "LipModel";
                    break;
                default:
                    meshName = "mesh-" + currentCategory;
                    break;
            }
            if (meshName === "NoseModel") {
                mainStage.changeColor("mesh-head", normalizedColor);
                mainStage.changeColor("NoseModel", normalizedColor);
            } else if (meshName === "mesh-footl" || meshName === "mesh-footr") {
                mainStage.changeColor("mesh-footl", normalizedColor);
                mainStage.changeColor("mesh-footr", normalizedColor);
            } else {
                mainStage.changeColor(meshName, normalizedColor);
            }
        }
    };

    hexToRgb(hex) {
        let r = 0, g = 0, b = 0;
        if (hex.length === 7) {
            r = parseInt(hex.slice(1, 3), 16) / 255;
            g = parseInt(hex.slice(3, 5), 16) / 255;
            b = parseInt(hex.slice(5, 7), 16) / 255;
        } else if (hex.length === 4) {
            r = parseInt(hex.slice(1, 2) + hex.slice(1, 2), 16) / 255;
            g = parseInt(hex.slice(2, 3) + hex.slice(2, 3), 16) / 255;
            b = parseInt(hex.slice(3, 4) + hex.slice(3, 4), 16) / 255;
        }
        return { r, g, b, a: 1 };
    }

    getRandomColor(category) {
        const colors = colorPalettes[category];
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    }

    // Add this function to apply random colors to the model
    applyRandomColors = () => {
        const { mainStage } = this.props;
        const categories = ["head", "hair", "beard", "stand", "eyebrow"];
        let headColor;
        categories.forEach(category => {
            let modelCategory;
            switch (category) {
                default:
                    modelCategory = "mesh-" + "hair";
                    break;
            }

            let hexColor;
            if (category === "head") {
                hexColor = this.getRandomColor(category);
                headColor = hexColor;
            } else if (["nose"].includes(category)) {
                hexColor = headColor;
            } else {
                hexColor = this.getRandomColor(category);
            }

            const rgbColor = this.hexToRgb(hexColor);
            mainStage.changeColor(modelCategory, rgbColor);

            if (category === "head") {
                mainStage.changeColor("NoseModel", rgbColor);
            //     mainStage.changeColor("mesh-handl", rgbColor);
            //     mainStage.changeColor("mesh-handr", rgbColor);
            //     mainStage.changeColor("mesh-arml", rgbColor);
            //     mainStage.changeColor("mesh-armr", rgbColor);
            } else if (category === "foot") {
                // mainStage.changeColor("mesh-footl", rgbColor);
                mainStage.changeColor("mesh-footr", rgbColor);
            } else if (category === "hair") {
                mainStage.changeColor("HairModel", rgbColor);
                mainStage.changeColor("BeardModel", rgbColor);
            }
        });
    };


    toggleColorPicker = () => {
        this.setState(prevState => ({
            isColorPickerVisible: !prevState.isColorPickerVisible,
        }));
    };

    updateCategory = (name) => {
        this.setState({ 
            currentCategory: name,
            showFaceSubCategory: name === "eye" // Update as needed
        });
    };

    render() {
        const { category, UIDisplayed } = this.props;
        const { currentCategory, showFaceSubCategory } = this.state;
        const colorOptions = colorPalettes[currentCategory] || [];
        const categoryDiv = category.filter(cat => cat.name !== "beard").map((cat, i) => {
            const name = cat.name;
            const file = cat.img_file;
            const isSelected = name === currentCategory   

            const iconFile = name === cat.name && isSelected ? `selected${cat.img_file}` : file;
            
            return (
                <div
                    className={`category ${isSelected ? 'selected-category' : ''}`}
                    key={i}
                    onClick={() => {
                        this.props.updateCategory(name);
                        this.setState({ 
                            currentCategory: name,
                            showFaceSubCategory: name === "eye"
                        });
                        let meshType;
                        switch (name) {
                            case "head":
                                meshType = "Head";
                                break;
                            case "torso":
                                meshType = "Torso";
                                break;
                            case "pose":
                                meshType = "pose";
                                break;
                            case "stand":
                                meshType = "mesh-stand";
                                break;
                            case "hair":
                                meshType = "HairModel";
                                break;
                            case "beard":
                                meshType = "BeardModel";
                                break;
                            case "nose":
                                meshType = "NoseModel";
                                break;
                            case "eyebrow":
                                meshType = "EyeBrowModel";
                                break;
                            case "eye":
                                meshType = "EyeModel";
                                break;
                            case "lip":
                                meshType = "LipModel";
                                break;        
                            default:
                                meshType = undefined;
                        }
                        if (meshType) {
                            window.selectedMesh(meshType);
                        }
                    }}
                >
                    <img src={`img/graphics_creation/${iconFile}`} alt={name} />
                </div>
            );
        });

        const faceCategoryDiv = faceSubCategory.map((cat, i) => {
            const name = cat.name;
            const file = cat.img_file;
            const isSelected = name === currentCategory;

            const iconFile = name === cat.name && isSelected ? `selected${cat.img_file}` : file;

            return (
                <div
                    className={`category ${isSelected ? 'selected-category' : ''}`}
                    key={i}
                    onClick={() => {
                        this.props.updateCategory(name);
                        this.setState({ currentCategory: name });

                        let meshType;
                        switch (name) {
                            case "beard":
                                meshType = "Beard";
                                break;
                            case "hair":
                                meshType = "Hair";
                                break;
                            case "nose":
                                meshType = "Nose";
                                break;
                            case "eyebrow":
                                meshType = "Eyebrow";
                                break;
                            case "eye":
                                meshType = "Eye";
                                break;
                            case "lip":
                                meshType = "Lip";
                                break;
                            default:
                                meshType = undefined;
                        }
                        if (meshType) {
                            window.selectedMesh(meshType);
                        }
                    }}
                >
                    <img src={`img/graphics_creation/${iconFile}`} alt={name} />
                </div>
            );
        });

        const colorSelector = (
            <div
                className="color-selector"
                style={{ width: `${colorOptions.length * 42}px` }} // 40px for color-option width + 2px for margins
            >
                {colorOptions.map((color, i) => (
                    <div
                        key={i}
                        className="color-option"
                        style={{ backgroundColor: color }}
                        onClick={() => this.handleColorChange(color)}
                    />
                ))}
            </div>
        );

        if (UIDisplayed) {
            return (
                <div>
                <div className="abs right panel">
                    <div className="abs rigth left-side">
                        { categoryDiv &&  (
                        <div className="abs right maincategory">
                            {categoryDiv}
                        </div>
                        )}
                    </div>
                    <button className="color-pallet" title="Randomize colors" onClick={this.applyRandomColors}>
                        <img className="color-pallet-icon" src={ColorPalletLogo} alt="logo" />
                    </button>
                        {showFaceSubCategory && (
                        <div className="abs right middle-side">
                            <div className="abs subcategory">
                                {faceCategoryDiv}
                            </div>
                            </div>

                        )}
                    
                </div>
                <div className="front">
                    {colorOptions.length > 0 && colorSelector}
                </div>
                <Selector
                        currentCategory={this.state.currentCategory}
                        updateCategory={this.updateCategory}
                        isLeft={this.state.isLeft}
                        updateLeft={this.updateLeft}
                        loadedMeshes={this.props.loadedMeshes}
                        updateMeshes={this.props.updateMeshes}
                        updatePopup={this.props.updatePopup}
                        updatePopupMessage={this.props.updatePopupMessage}
                        editor={this.props.editor}
                        updateLoading={this.props.updateLoading}
                    />
                </div>
            );
        } else {
            return <div />;
        }
    }
}

export default Category;
