import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import postsData from '../library/posts.json';
import "../css/TopicPage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment } from '@fortawesome/free-regular-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import topicsData from '../library/topics.json';

const TopicPage = () => {
    const { topic } = useParams();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const filteredPosts = postsData.filter(post => post.topic === topic);
        setPosts(filteredPosts);
    }, [topic]);

    return (
        <div className="topic-page">
            <h1>{topic}</h1>
            <div className='posts-container'>
            {posts.map(post => (
                    <div key={post.id} className='post'>
                        <div className="user-info">
                            {post.userImage && <img src={post.userImage} alt="userImage" />}
                            <h4>{post.user}</h4>
                            <span className="timestamp">{post.timestamp}</span>
                        </div>
                        <p>{post.content}</p>
                        {post.image && <img src={post.image} alt="Post visual" />}
                        <div className='post-interactions'>
                            <button> <FontAwesomeIcon icon={faHeart} /> {post.likes}</button>
                            <button><FontAwesomeIcon icon={faComment} /> {post.comments}</button>
                            <button><FontAwesomeIcon icon={faShareNodes} /> {post.shares}</button>
                        </div>
                    </div>
                ))}
                <div className= 'popular-topics'>
                    <div className= ''>
                        <h2> Popular Topics</h2>
                        {topicsData.map(({ category, topics }) => (
                            <div key={category} className="category-section">
                                <strong>{category}</strong>
                                <div className='topics-list'>
                                    {topics.map(topic => (
                                        <Link
                                            to={`/forum/${topic}`}
                                            key={topic}
                                            className="topic-item"
                                            onClick=""
                                        >
                                            {topic}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopicPage;
