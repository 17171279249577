// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BrainStormPanel.css */
.brainstorm-panel {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 50%;
    height: 100%;
    background-color: white;
    border-left: 2px solid #e0e0e0;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    overflow-y: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 998; 
    backdrop-filter: blur(1px);
    pointer-events: none;
}

.title {
    margin-top: 10px;
    margin-left: 30px;
    overflow-x: hidden;
}

.questions {
    margin-top: 20px;
    margin-right: 20px;
    width: 90%;
    background-color: #F7F8F8;
    padding: 10px 10px 50px 30px;
    size: 20px;
    font-family: EB Garamond;
    line-height: 26.1px;
    font-weight: 400px;
}

.questions p {
    margin-bottom: -20px;
}

.questions p:first-child {
    margin-bottom: 10px; /* Add extra bottom margin after the title */
}

.input-container {
    margin: auto;
    width: 113%;
}`, "",{"version":3,"sources":["webpack://./src/css/BrainStormPanel.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,cAAc;IACd,YAAY;IACZ,uBAAuB;IACvB,8BAA8B;IAC9B,kBAAkB;IAClB,uCAAuC;IACvC,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,UAAU;IACV,YAAY;IACZ,0CAA0C;IAC1C,YAAY;IACZ,0BAA0B;IAC1B,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,yBAAyB;IACzB,4BAA4B;IAC5B,UAAU;IACV,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB,EAAE,4CAA4C;AACrE;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":["/* BrainStormPanel.css */\n.brainstorm-panel {\n    position: fixed;\n    top: 0;\n    right: 0;\n    max-width: 50%;\n    height: 100%;\n    background-color: white;\n    border-left: 2px solid #e0e0e0;\n    border-radius: 2px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    z-index: 999;\n    overflow-y: auto;\n}\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 50%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n    z-index: 998; \n    backdrop-filter: blur(1px);\n    pointer-events: none;\n}\n\n.title {\n    margin-top: 10px;\n    margin-left: 30px;\n    overflow-x: hidden;\n}\n\n.questions {\n    margin-top: 20px;\n    margin-right: 20px;\n    width: 90%;\n    background-color: #F7F8F8;\n    padding: 10px 10px 50px 30px;\n    size: 20px;\n    font-family: EB Garamond;\n    line-height: 26.1px;\n    font-weight: 400px;\n}\n\n.questions p {\n    margin-bottom: -20px;\n}\n\n.questions p:first-child {\n    margin-bottom: 10px; /* Add extra bottom margin after the title */\n}\n\n.input-container {\n    margin: auto;\n    width: 113%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
