import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../css/Forum.css";
import topicsData from '../library/topics.json';

const Forum = () => {
    const [showModal, setShowModal] = useState(true);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className='forum'>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Pick a topic you would like to see in your feed:</h2>
                        <div className="topics-grid">
                            {topicsData.map(({ category, topics }) => (
                                <div key={category} className="category-section">
                                    <strong>{category}</strong>
                                    <div className="topics-list">
                                        {topics.map(topic => (
                                            <Link
                                                to={`/forum/${topic}`}
                                                key={topic}
                                                className="topic-item"
                                                onClick={handleCloseModal}
                                            >
                                                {topic}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <button className="close-button" onClick={handleCloseModal}>Close</button> */}
                    </div>
                </div>
            )}
            <div className='posts'>
                <h2></h2>
                {/* Additional content can go here */}
            </div>
            <div className='rightbar'>
                {/* Additional content can go here */}
            </div>
        </div>
    );
}

export default Forum;
