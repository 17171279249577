// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screen {
    width: 100%;
    height: 100%;
    background-color: rgba(40, 44, 52, 0.5);
    z-index: 5000;
}

.popup {
    height: 350px;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #282c34;
    box-shadow: 0 0 50px #07070760;
    border-radius: 10px;
    color: white;
}

.message {
    font-size: 15px;
    font-weight: 700;
    width: calc(100%);
    height: 100px;
    text-align: center;
    vertical-align: center;
    transform: translate(-50%, -50%);
}

.message p {
    width: 100%;
    margin: 0;
    position: absolute;
    left: 50%;
}

.message p a {
    color: #61dafb;
}

.img-container {
    text-align: left;
    width: 50%;
    height: 50%;
    margin-left: 25%;
    padding-top: 50px;
    padding-bottom: 100px;
    animation: scale 2.5s ease-in-out 0s infinite normal forwards;
}

.info-logo {
    animation: App-logo-spin infinite 8s linear;
    pointer-events: none;
}

.cross {
    top: 10px;
    left: 10px;
    cursor: pointer;
    font-size: 20px;
}

.cross:hover {
    color: #61dafb;
}`, "",{"version":3,"sources":["webpack://./src/css/Popup.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uCAAuC;IACvC,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAyB;IACzB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,6DAA6D;AACjE;;AAEA;IACI,2CAA2C;IAC3C,oBAAoB;AACxB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".screen {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(40, 44, 52, 0.5);\n    z-index: 5000;\n}\n\n.popup {\n    height: 350px;\n    width: 500px;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #282c34;\n    box-shadow: 0 0 50px #07070760;\n    border-radius: 10px;\n    color: white;\n}\n\n.message {\n    font-size: 15px;\n    font-weight: 700;\n    width: calc(100%);\n    height: 100px;\n    text-align: center;\n    vertical-align: center;\n    transform: translate(-50%, -50%);\n}\n\n.message p {\n    width: 100%;\n    margin: 0;\n    position: absolute;\n    left: 50%;\n}\n\n.message p a {\n    color: #61dafb;\n}\n\n.img-container {\n    text-align: left;\n    width: 50%;\n    height: 50%;\n    margin-left: 25%;\n    padding-top: 50px;\n    padding-bottom: 100px;\n    animation: scale 2.5s ease-in-out 0s infinite normal forwards;\n}\n\n.info-logo {\n    animation: App-logo-spin infinite 8s linear;\n    pointer-events: none;\n}\n\n.cross {\n    top: 10px;\n    left: 10px;\n    cursor: pointer;\n    font-size: 20px;\n}\n\n.cross:hover {\n    color: #61dafb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
