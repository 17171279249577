import React, { useState } from 'react';
import "../css/BrainStorm.css";
import BrainStormPanel from './BrainStormPanel';

const BrainStorm = () => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [topics, setTopics] = useState([
        "I’d like to build a pomodoro timer",
        "How to 3D model a Baymax figurine",
        "Remote controlled car"
    ]);
    const [showPanel, setShowPanel] = useState(false);

    const handleStartBrainstorming = () => {
        setShowInput(true);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleStartBrainstormingClick = () => {
        if (inputValue.trim() !== "") {
            setTopics([...topics, inputValue.trim()]);
            setShowPanel(true);
        }
    };

    return (
        <div className='brain-storm'>
            <div className={`topics ${showPanel ? 'blur' : ''}`}>
                <h5> All topics </h5>
                <section className='items'>
                    {topics.map((topic, index) => (
                        <article key={index}>{topic}</article>
                    ))}
                </section>
                {!showInput && (
                    <button className='topic-btn' onClick={handleStartBrainstorming}> New topic</button>
                )}
                {showInput && (
                    <div className="brainstorm-container">
                        <input
                            type="text"
                            className="brainstorm-input"
                            placeholder="What do you want to brainstorm on?"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                        <button className="start-brainstorming-btn" onClick={handleStartBrainstormingClick}>Start brainstorming</button>
                    </div>
                )}
            </div>
            {showPanel && (
                <>
                <div className="overlay"></div>
                <BrainStormPanel  showInput={showInput} title={showInput ? inputValue : "Default Title" }/>
            </>
            )}
        </div>
    );
}

export default BrainStorm;