// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
    width: 30vmin;
    height: 30vmin;
    border-radius: 100%;
    overflow: hidden;
    top: calc(50% - 15vmin);
    left: calc(50% - 15vmin);
    background-color: #e1e1e1;
    animation: scale 2.5s ease-in-out 0s infinite normal forwards;
}

.circle img {
    width: 80%;
    animation: rotation 2.5s ease-in-out 0s infinite normal forwards;
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes scale {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,wBAAwB;IACxB,yBAAyB;IACzB,6DAA6D;AACjE;;AAEA;IACI,UAAU;IACV,gEAAgE;AACpE;;AAEA;IACI;QACI,oBAAoB;IACxB;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".circle {\n    width: 30vmin;\n    height: 30vmin;\n    border-radius: 100%;\n    overflow: hidden;\n    top: calc(50% - 15vmin);\n    left: calc(50% - 15vmin);\n    background-color: #e1e1e1;\n    animation: scale 2.5s ease-in-out 0s infinite normal forwards;\n}\n\n.circle img {\n    width: 80%;\n    animation: rotation 2.5s ease-in-out 0s infinite normal forwards;\n}\n\n@keyframes rotation {\n    0% {\n        transform: rotate(0);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n@keyframes scale {\n    0% {\n        transform: scale(0.8);\n    }\n    50% {\n        transform: scale(1);\n    }\n    100% {\n        transform: scale(0.8);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
