// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
    border: none;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    left: 10%;
    font-size: 18px;
    transition: box-shadow 0.3s, transform 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: #D80577;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
}

.buttons::before {
    content: '';
    position: absolute;
    top: 10%;
    left: 10%;
    width: auto;
    height: auto;
    z-index: 1;
    transition: opacity 0.3s;
}

.buttons:hover {
    box-shadow: 0 6px 12px rgba(178, 178, 178, 0.4);
}

.buttons:active {
    background-color: #b30000;
    box-shadow: 0 2px 4px #76000066;
    transform: translateY(4px);
}

.buttons span {
    position: relative;
    z-index: 2;
}

#download {
    bottom: 20px;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/css/Buttons.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,SAAS;IACT,eAAe;IACf,2CAA2C;IAC3C,wCAAwC;IACxC,yBAAyB;IACzB,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,yBAAyB;IACzB,+BAA+B;IAC/B,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,UAAU;IACV,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".buttons {\n    border: none;\n    border-radius: 50%;\n    width: 150px;\n    height: 150px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    cursor: pointer;\n    user-select: none;\n    left: 10%;\n    font-size: 18px;\n    transition: box-shadow 0.3s, transform 0.3s;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n    background-color: #D80577;\n    font-family: 'Lato', sans-serif;\n    font-weight: 900;\n    font-size: 36px;\n    line-height: 43px;\n    text-align: center;\n}\n\n.buttons::before {\n    content: '';\n    position: absolute;\n    top: 10%;\n    left: 10%;\n    width: auto;\n    height: auto;\n    z-index: 1;\n    transition: opacity 0.3s;\n}\n\n.buttons:hover {\n    box-shadow: 0 6px 12px rgba(178, 178, 178, 0.4);\n}\n\n.buttons:active {\n    background-color: #b30000;\n    box-shadow: 0 2px 4px #76000066;\n    transform: translateY(4px);\n}\n\n.buttons span {\n    position: relative;\n    z-index: 2;\n}\n\n#download {\n    bottom: 20px;\n}\n\nbutton {\n    background: none;\n    color: inherit;\n    border: none;\n    padding: 0;\n    font: inherit;\n    cursor: pointer;\n    outline: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
