import React from 'react';
import Sidebar from './Sidebar';
import '../css/Home.css';
import homesvg from '../home.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    return (
        <div className='home'>
            <div className='banner'>
                <div className='rectangle-57'></div>
                <div className='heading'>What do you want to build?</div>
                <div className='input-container-Homepage'>
                    <div className='placeholder'>A model, a car chassis etc</div>
                        <FontAwesomeIcon className='arrow-icon' icon={faArrowRight} />
                </div>
                <div className='subtitle'>Here are some ideas to help you get started</div>
                <div className='tag'>
                    <div className='tag-text'>3D model of Baymax</div>
                </div>
                <div className='tag' style={{ left: '590px' }}>
                    <div className='tag-text' style={{ left: '15px' }}>Remote controlled car</div>
                </div>
                <div className='tag' style={{ left: '775px' }}>
                    <div className='tag-text' style={{ left: '15px' }}>Resin printed earrings</div>
                </div>
            </div>
        </div>
    );
}

export default Home;
