import React, { useState } from 'react';
import '../css/Auth.css';
import mamaarlogo from '../mamaarlogo.png'
const Auth = ({ setSession }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState(null);

  const handleAuth = async () => {
    try {
      if (isLogin) {
        const response = await fetch('https://mamaar.ai/api/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || 'Login failed');
        setSession(data.access_token);
      } else {
        const response = await fetch('https://mamaar.ai/api/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password, firstName: name, lastName: name }),
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || 'Registration failed');
        alert('Registration successful. Please check your email for verification.');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="background-layer"></div>
      <div className="login-box">
        <img src={mamaarlogo} className="logo" alt="logo" />
        {/* <h2>{isLogin ? 'Login' : 'Register'}</h2> */}
        {error && <p className="error">{error}</p>}
        {!isLogin && (
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input-field"
          />
        )}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <button onClick={handleAuth} className="auth-button">{isLogin ? 'Login' : 'Register'}</button>
        <button onClick={() => setIsLogin(!isLogin)} className="toggle-button">
          {/* {isLogin ? 'Need to Register?' : 'Already have an account?'} */}
        </button>
      </div>
    </div>
  );
};

export default Auth;
