import React, { useState, useEffect } from 'react';
import '../css/BrainStormPanel.css';

const BrainStormPanel = ({ title }) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [questions, setQuestions] = useState([]);
    const [requestCount, setRequestCount] = useState(1);

    useEffect(() => {
        fetchSteps();
    }, []);

    const fetchSteps = async () => {
        const response = await fetch('http://localhost:8000/aiquestions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ requestCount }),
        });
        const data = await response.json();
        const initialSteps = data.description.split('\n').map(line => line.trim() === '' ? '\u00A0' : line);
        setQuestions([...initialSteps, '\u00A0']);
        setShowInput(true);
    };

    const handleInputChange = async () => {
        if (inputValue.toLowerCase() === 'done') {
            setShowInput(false);
            setInputValue("");
        } else {
            const newRequestCount = requestCount + 1;
            setRequestCount(newRequestCount);

            const response = await fetch('http://localhost:8000/aiquestions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ requestCount: newRequestCount }),
            });
            const data = await response.json();
            const additionalSteps = data.description.split('\n').map(line => line.trim() === '' ? '\u00A0' : line);
            setQuestions(prevSteps => [...prevSteps, '\u00A0', ...additionalSteps, '\u00A0']);
            setInputValue("");
        }
    };

    return (
        <div className='brainstorm-panel'>
            <div className='title'>
                <h2>{title}</h2>
                <section className='questions'>
                    {questions.map((question, index) => (
                        <p key={index} className="question-line">{question}</p>
                    ))}
                </section>
                {showInput && (
                    <div className="input-container">
                        <input
                            type="text"
                            className="brainstorm-input"
                            placeholder="Type 'Done' to stop or press Enter to continue"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleInputChange();
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrainStormPanel;
