// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name {
    font-size: 14px;
    margin: 20px 50px 50px 160px;
    padding: 5px 15px 5px 15px;
    color: #006D83;
    transition: background-color 0.3s;
    background-color: rgba(97, 218, 251, 0.1);
    box-shadow: 0 0 10px rgba(97, 218, 251, 0.2);
    border-radius: 10px;
    display: none;
}

.name:hover {
    background-color: rgba(97, 218, 251, 0.5);
    box-shadow: 0 0 10px #61dafb80;
}

[contenteditable]:focus {
    outline: 0 solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/css/Name.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,4BAA4B;IAC5B,0BAA0B;IAC1B,cAAc;IACd,iCAAiC;IACjC,yCAAyC;IACzC,4CAA4C;IAC5C,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yCAAyC;IACzC,8BAA8B;AAClC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".name {\n    font-size: 14px;\n    margin: 20px 50px 50px 160px;\n    padding: 5px 15px 5px 15px;\n    color: #006D83;\n    transition: background-color 0.3s;\n    background-color: rgba(97, 218, 251, 0.1);\n    box-shadow: 0 0 10px rgba(97, 218, 251, 0.2);\n    border-radius: 10px;\n    display: none;\n}\n\n.name:hover {\n    background-color: rgba(97, 218, 251, 0.5);\n    box-shadow: 0 0 10px #61dafb80;\n}\n\n[contenteditable]:focus {\n    outline: 0 solid transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
