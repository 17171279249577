// src/App.js
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './components/Sidebar';
import Auth from './components/Auth';
import './css/App.css';

const App = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const savedToken = localStorage.getItem('access_token');
    if (savedToken) {
      setSession(savedToken);
    }
  }, []);

  const handleSetSession = (token) => {
    if (token) {
      localStorage.setItem('access_token', token);
      setSession(token);
    } else {
      localStorage.removeItem('access_token');
      setSession(null);
    }
  };

  if (!session) {
    return <Auth setSession={handleSetSession} />;
  }

  return (
    <div className="App">
      <SideBar />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default App;
