// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
    display: flex;
    flex-wrap: nowrap;
}

.numeric-input {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
}

.controls {
    position: absolute;
    height: calc(100% - 10px);
    top: 0;
    left: 18px;
    width: calc(100% - 10px);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;

    color: rgb(255, 255, 255);
    font-size: 12px;
    z-index: 10;
}

.control {
    width: 33%;
    margin-left: 2px;
}

.bone-control {
    display: block;
    margin: 10px 2px;
}

.bone-control p {
    margin-bottom: 0;
    color: white;
}

.export {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(144, 144, 144, 0.2);
    border: 1px white solid;
    color: white;
    background-color: #00000059;
    cursor: pointer;
    text-align: center;
    vertical-align: center;
    line-height: 38px;
    font-size: 18px;
    margin-bottom: 10px;
}

.export:hover {
    background-color: rgba(97, 218, 251, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/css/Editor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,oCAAoC;IACpC,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,MAAM;IACN,UAAU;IACV,wBAAwB;IACxB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;;IAElB,yBAAyB;IACzB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,0CAA0C;IAC1C,uBAAuB;IACvB,YAAY;IACZ,2BAA2B;IAC3B,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".flex-container {\n    display: flex;\n    flex-wrap: nowrap;\n}\n\n.numeric-input {\n    color: white;\n    background-color: rgba(0, 0, 0, 0.5);\n    width: 100%;\n}\n\n.controls {\n    position: absolute;\n    height: calc(100% - 10px);\n    top: 0;\n    left: 18px;\n    width: calc(100% - 10px);\n    display: block;\n    overflow-x: hidden;\n    overflow-y: scroll;\n\n    color: rgb(255, 255, 255);\n    font-size: 12px;\n    z-index: 10;\n}\n\n.control {\n    width: 33%;\n    margin-left: 2px;\n}\n\n.bone-control {\n    display: block;\n    margin: 10px 2px;\n}\n\n.bone-control p {\n    margin-bottom: 0;\n    color: white;\n}\n\n.export {\n    width: 100%;\n    height: 40px;\n    border-radius: 10px;\n    background-color: rgba(144, 144, 144, 0.2);\n    border: 1px white solid;\n    color: white;\n    background-color: #00000059;\n    cursor: pointer;\n    text-align: center;\n    vertical-align: center;\n    line-height: 38px;\n    font-size: 18px;\n    margin-bottom: 10px;\n}\n\n.export:hover {\n    background-color: rgba(97, 218, 251, 0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
