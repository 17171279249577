import React, { useState, useEffect } from 'react';
import { CustomView, isMobileOnly, isTablet, isBrowser, withOrientationChange } from "react-device-detect";
import MainStage from "./webgl/MainStage";
import PageLoader from "./PageLoader";
import Name from "./Name";
import Footer from "./Footer";
import Buttons from "./Buttons";
import Popup from "./Popup";
import Category from "./Category";
import PartLoader from "./PartLoader";
import CategoryList from "../library/category.json";
import '../css/App.css';
import logo from '../logo1.svg';
import Selector from './Selector';

const Tools = () => {
    const [state, setState] = useState({
        category: CategoryList,
        currentCategory: "hair",
        avatarName: "your_model",
        UIDisplayed: true,
        popup: false,
        mainStage: null,
        loadedMeshes: {},
        editor: false,
        partLoading: false,
        message: "Sorry, this feature is still under development...",
        loaded: false,
    });

    useEffect(() => {
        const mainStage = new MainStage();
        setState((prevState) => ({ ...prevState, mainStage }));

        if (isBrowser || isTablet) {
            mainStage.init();
            mainStage.animate();
        }

        setState((prevState) => ({ ...prevState, loaded: true }));

        return () => {
            mainStage.dispose();
        };
    }, []);

    const updateCategory = currentCategory => setState({ ...state, currentCategory });
    const updateAvatarName = avatarName => setState({ ...state, avatarName });
    const updatePopup = popup => setState({ ...state, popup });
    const updateMeshes = loadedMeshes => setState({ ...state, loadedMeshes });
    const updateLoading = partLoading => setState({ ...state, partLoading });
    const updatePopupMessage = message => setState({ ...state, message });

    if (!state.loaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <CustomView condition={isBrowser || isTablet}>
                <PageLoader />
                <Name
                    avatarName={state.avatarName}
                    updateAvatarName={updateAvatarName}
                />
                <Footer />
                <Buttons
                    avatarName={state.avatarName}
                    loadedMeshes={state.loadedMeshes}
                />
                <Popup
                    popup={state.popup}
                    message={state.message}
                    updatePopup={updatePopup}
                />
                
                <Category
                    category={state.category}
                    currentCategory={state.currentCategory}
                    updateCategory={updateCategory}
                    UIDisplayed={state.UIDisplayed}
                    loadedMeshes={state.loadedMeshes}
                    updateMeshes={updateMeshes}
                    updatePopup={updatePopup}
                    updatePopupMessage={updatePopupMessage}
                    editor={state.editor}
                    updateLoading={updateLoading}
                    mainStage={state.mainStage}
                />
                <PartLoader
                    loading={state.partLoading}
                    updateLoading={updateLoading}
                />
            </CustomView>
            <CustomView condition={isMobileOnly}>
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="Logo" />
                        <div className="full-screen-message">
                                
                        </div>
                    </header>
                </div>
            </CustomView>
        </div>
    );
};

export default withOrientationChange(Tools);
