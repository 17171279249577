import React, { useState } from 'react';
import axios from 'axios';
import '../css/ContextTo3D.css'; // Import CSS for styling

const ContextTo3D = () => {
  const [textInput, setTextInput] = useState('');
  const [fileInput, setFileInput] = useState(null);
  const [objFile, setObjFile] = useState(null);

  const handleTextChange = (e) => setTextInput(e.target.value);
  const handleFileChange = (e) => setFileInput(e.target.files[0]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('text', textInput);
    formData.append('type', fileInput ? 'image' : 'text');
    if (fileInput) {
      formData.append('file', fileInput);
    }

    try {
      const response = await axios.post('ApiEndpointToAddHere', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setObjFile(response.data.objUrl || URL.createObjectURL(new Blob([response.data])));
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

  return (
    <div className="context-to-3d-container">
      <h1>Context to 3D</h1>
      <div className="input-section">
        <textarea
          placeholder="Enter your text here..."
          value={textInput}
          onChange={handleTextChange}
          className="text-input"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="file-input"
        />
        <button onClick={handleSubmit} className="submit-button">
          Generate 3D Model
        </button>
      </div>
      <div className="output-section">
        {objFile ? (
          <div className="obj-viewer">
            {/* Render the OBJ file using a viewer like three.js */}
            <a href={objFile} download="model.obj">Download 3D Model</a>
          </div>
        ) : (
          <p>No 3D model generated yet.</p>
        )}
      </div>
    </div>
  );
};

export default ContextTo3D;
