import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import BrainStorm from './components/BrainStorm';
import Tools from './components/Tools';
import Home from './components/Home';
import TopicPage from './components/TopicPage';
import Auth from './components/Auth';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Forum from './components/Forum';
import ContextTo3D from './components/contextTo3D';
const router = createBrowserRouter([
    {
        path: '/',
        element: <App />, // Root layout
        children: [
            // { path: '/', element: <Home /> },
            { path: '/', element: <Tools /> },
            { path: '/Auth', element: <Auth /> },
            // { path: '/tools', element: <Tools /> },
            { path: '/tools/context-to-3d', element: <ContextTo3D /> },
            { path: '/brainstorm', element: <BrainStorm /> },
            { path: '/Forum', element: <Forum /> },
            { path: '/forum/:topic', element: <TopicPage /> },
        ],
    },
  ]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
