// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-to-3d-container {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: #9f9f9f;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .input-section {
    margin-bottom: 20px;
  }
  
  .text-input {
    width: 80%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-bottom: 20px;
    resize: none;
  }
  
  .file-input {
    display: block;
    margin: 0 auto 20px auto;
    font-size: 16px;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #0f101f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .output-section {
    margin-top: 30px;
  }
  
  .obj-viewer {
    margin-top: 20px;
  }
  
  .obj-viewer a {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #28a745;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .obj-viewer a:hover {
    background-color: #218838;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/ContextTo3D.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,cAAc;IACd,wBAAwB;IACxB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".context-to-3d-container {\n    position: absolute;\n    top: 10%;\n    left: 20%;\n    width: 60%;\n    margin: 0 auto;\n    text-align: center;\n    padding: 20px;\n    background-color: #9f9f9f;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .input-section {\n    margin-bottom: 20px;\n  }\n  \n  .text-input {\n    width: 80%;\n    height: 100px;\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n    font-size: 16px;\n    margin-bottom: 20px;\n    resize: none;\n  }\n  \n  .file-input {\n    display: block;\n    margin: 0 auto 20px auto;\n    font-size: 16px;\n  }\n  \n  .submit-button {\n    padding: 10px 20px;\n    font-size: 18px;\n    color: #fff;\n    background-color: #0f101f;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .submit-button:hover {\n    background-color: #0056b3;\n  }\n  \n  .output-section {\n    margin-top: 30px;\n  }\n  \n  .obj-viewer {\n    margin-top: 20px;\n  }\n  \n  .obj-viewer a {\n    display: inline-block;\n    padding: 10px 20px;\n    color: #fff;\n    background-color: #28a745;\n    border-radius: 5px;\n    text-decoration: none;\n  }\n  \n  .obj-viewer a:hover {\n    background-color: #218838;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
