// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forum {
    position: absolute;
    left: 9%;
    width: 91%;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
}

.posts {
    width: 70%;
    height: fit-content;
    position: absolute;
}

.rightbar {
    width: 31%;
    height: 60%;
    position: absolute;
    left: 70%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.topics-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    margin-top: 20px;
}

.category-section {
    width: 100%;
}

.topics-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.topic-item {
    background: #f0f0f0;
    padding: 10px 20px;
    border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Forum.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".forum {\n    position: absolute;\n    left: 9%;\n    width: 91%;\n    height: 100%;\n    flex-grow: 1;\n    overflow: hidden;\n}\n\n.posts {\n    width: 70%;\n    height: fit-content;\n    position: absolute;\n}\n\n.rightbar {\n    width: 31%;\n    height: 60%;\n    position: absolute;\n    left: 70%;\n}\n\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.modal {\n    background: #fff;\n    padding: 20px;\n    border-radius: 10px;\n    width: 80%;\n    max-width: 400px;\n    text-align: center;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n}\n\n.topics-grid {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    gap: 10px;\n    margin-top: 20px;\n}\n\n.category-section {\n    width: 100%;\n}\n\n.topics-list {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.topic-item {\n    background: #f0f0f0;\n    padding: 10px 20px;\n    border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
